import {useTranslation} from 'react-i18next';
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import React from 'react';

import './albumsListSortingMenu.css';

export enum AlbumsListSortingOption {
    relevancy,
    yearNew,
    yearOld,
    artist,
    title
}

type AlbumsListSortingOptionProps = {
    selectedOption: AlbumsListSortingOption,
    optionSelectionHandler(option: AlbumsListSortingOption): void,
    includeRelevance?: boolean,
    includeArtist?: boolean
}

export function AlbumsListSortingMenu(props: AlbumsListSortingOptionProps): JSX.Element {
  const {t} = useTranslation();

  const sortingOptions = new Map<AlbumsListSortingOption, string>();
  if (props.includeRelevance ?? true) {
    sortingOptions.set(AlbumsListSortingOption.relevancy, t('AlbumsList.SortOption.Relevance'));
  }
  sortingOptions.set(AlbumsListSortingOption.yearNew, t('AlbumsList.SortOption.YearNew'));
  sortingOptions.set(AlbumsListSortingOption.yearOld, t('AlbumsList.SortOption.YearOld'));
  if (props.includeArtist ?? true) {
    sortingOptions.set(AlbumsListSortingOption.artist, t('AlbumsList.SortOption.Artist'));
  }
  sortingOptions.set(AlbumsListSortingOption.title, t('AlbumsList.SortOption.Title'));

  const menuItems = Array.from(sortingOptions.keys()).map(option => {
    return <MenuItem
      key={option}
      type="checkbox"
      checked={props.selectedOption === option}
      onClick={() => props.optionSelectionHandler(option)}>
      {sortingOptions.get(option)}
    </MenuItem>;
  });

  return <Menu
    menuButton={
      <MenuButton className="albums-list-sorting-menu">
        {sortingOptions.get(props.selectedOption)}
        <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
      </MenuButton>
    }

    theming="dark">
    {menuItems}
  </Menu>;
}