/* eslint @typescript-eslint/no-non-null-assertion: 'off' */

import React, {FunctionComponent, useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {Menu, MenuButton, MenuDivider, MenuHeader, MenuItem, SubMenu} from '@szhsin/react-menu';
import copy from 'copy-to-clipboard';

import SpotifyApiContext from '../../spotify/spotifyApiContext';
import SpotifyTrack from '../../spotify/spotifyTrack';

import useCollectTrack from '../../hooks/your-mix/useCollectTrack';
import {isTrackCollected} from '../../redux/your-mix/yourMix.selector';
import useRemoveTrack from '../../hooks/your-mix/useRemoveTrack';
import useAlbumMenuItems from '../album-menu-items/useAlbumMenuItems';
import useArtistMenuItems from '../artist-menu-items/useArtistMenuItems';
import usePlayTrack from '../../hooks/player/usePlayTrack';
import usePersistYourMix from '../../hooks/your-mix/usePersistYourMix';
import useSubscriberFeaturesMenuItems from '../subscriber-features-menu-items/useSubscriberFeaturesMenuItems';

import './trackMenu.css';

type TrackMenuProps = {
    track: SpotifyTrack
}

export const TrackMenu: FunctionComponent<TrackMenuProps> = ({track, children}
    : React.PropsWithChildren<TrackMenuProps>) => {

  const { t } = useTranslation();
  const spotify = useContext(SpotifyApiContext);
  const [collectTrack] = useCollectTrack();
  const [persistYourMix] = usePersistYourMix();
  const [removeTrack] = useRemoveTrack();
  const albumMenuItems = useAlbumMenuItems(track.album!);
  const artistMenuItems = useArtistMenuItems(track.artists ?? track.album!.artists);
  const isCollected = useSelector(isTrackCollected(track.id));
  const subscriberFeatures = useSubscriberFeaturesMenuItems();
  const playTrack = usePlayTrack();
  const canPlay = spotify.canPlay();

  const handlePlayTrack = useCallback(() => playTrack(track), [track, playTrack]);

  const openTrackInSpotifyApp = () => {
    window.open(track.uri, '_blank');
  };

  const openTrackInSpotifyWebApp = () => {
    window.open(track.url, '_blank');
  };

  const addTrackToMix = (track: SpotifyTrack) => {
    collectTrack(track);
    persistYourMix();
  };

  const removeTrackFromMix = (track: SpotifyTrack) => {
    removeTrack(track);
    persistYourMix();
  };

  const openTrackInSongLink = async () => {
    const link = `https://song.link/s/${track.id}`;
    window.open(link, '_blank');
  };

  const getSearchString = () =>
    `${track.artists.map(a => a.name).join(', ')} "${track.name}"`;

  const copyTrackNameToClipboard = () => {
    copy(getSearchString());
  };

  const findInGoogle = () => {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(getSearchString())}`, '_blank');
  };

  const albumAndArtistItems = (!isMobile)
    ? [
      <MenuHeader key='tmmh1'>{t('AlbumMenu.AlbumLabel')}</MenuHeader>,
      ...albumMenuItems,
      <MenuDivider key='tmmd2'/>,
      <MenuHeader key='tmmh3'>{t('AlbumMenu.ArtistLabel', {count: track.artists.length})}</MenuHeader>,
      ...artistMenuItems
    ]
    : [
      <SubMenu key='tmsm4' label={t('AlbumMenu.AlbumLabel')}>{albumMenuItems}</SubMenu>,
      <SubMenu key='tmsm5' label={t('AlbumMenu.ArtistLabel', {count: track.artists.length})}>{artistMenuItems}</SubMenu>
    ];

  return <Menu
    className={'track-menu'}
    portal={true}
    arrow={true}
    align={'center'}
    menuButton={<MenuButton className={'track-menu-button'}>{children}</MenuButton>}
    theming={'dark'}>

    <MenuHeader key='tmmh6'>{t('TrackMenu.TrackLabel')}</MenuHeader>
    <MenuItem key='tmmi7' onClick={handlePlayTrack} disabled={!canPlay}>{t('TrackMenu.PlayTrack')}</MenuItem>
    {!isCollected && <MenuItem key='tmmi8' onClick={() => addTrackToMix(track)}>{t('TrackMenu.CollectTrack')}</MenuItem>}
    {isCollected && <MenuItem key='tmmi9' onClick={() => removeTrackFromMix(track)}>{t('TrackMenu.DropTrack')}</MenuItem>}
    <SubMenu key='tmsm10' label={t('TrackMenu.Open')}>
      <MenuItem key='tmmi11' onClick={openTrackInSpotifyApp}>{t('TrackMenu.OpenTrack')}</MenuItem>
      <MenuItem key='tmmi12' onClick={openTrackInSpotifyWebApp}>{t('TrackMenu.OpenTrackWeb')}</MenuItem>
      <MenuItem key='tmmi14' onClick={openTrackInSongLink}>{t('TrackMenu.OpenTrackInSongLink')}</MenuItem>
      <MenuDivider key='tmmi15'/>
      <MenuItem key='tmmi16' onClick={findInGoogle}>{t('TrackMenu.FindInGoogle')}</MenuItem>
      <MenuItem key='tmmi17' onClick={copyTrackNameToClipboard}>{t('TrackMenu.CopyName')}</MenuItem>
    </SubMenu>
    <MenuDivider key='tmmd13'/>
    {albumAndArtistItems}
    {subscriberFeatures}
  </Menu>;
};

export default TrackMenu;
