import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {permissionsSelector} from '../redux/permissions/permissions.selector';
import Logo from '../logo/logo';

import './logo.css';

export default function ClickableLogo(): JSX.Element {
  const {t} = useTranslation();
  const permissions = useSelector(permissionsSelector);

  const logoWrapper = (permissions.hasPersonalization)
    ? <a href="#" title={t('Logo.BackToBridge')}><Logo/></a>
    : <Logo/>;

  return <div className='app-title'>
    {logoWrapper}
  </div>;
}
