import {useTranslation} from 'react-i18next';
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import React from 'react';

import './tracksSortingMenu.css';

export enum TracksSortingOption {
    added,
    artist,
    title,
    yearNew,
    yearOld,
    popularityDesc,
    popularityAsc,
    random
}

type TracksSortingOptionProps = {
    selectedOption: TracksSortingOption,
    optionSelectionHandler(option: TracksSortingOption) : void
}

export function TracksSortingMenu(props: TracksSortingOptionProps): JSX.Element {
  const {t} = useTranslation();

  const sortingOptions = new Map<TracksSortingOption, string>();
  sortingOptions.set(TracksSortingOption.added, t('Tracks.SortOption.Added'));
  sortingOptions.set(TracksSortingOption.artist, t('Tracks.SortOption.Artist'));
  sortingOptions.set(TracksSortingOption.title, t('Tracks.SortOption.Title'));
  sortingOptions.set(TracksSortingOption.yearNew, t('Tracks.SortOption.YearNew'));
  sortingOptions.set(TracksSortingOption.yearOld, t('Tracks.SortOption.YearOld'));
  sortingOptions.set(TracksSortingOption.popularityDesc, t('Tracks.SortOption.PopularityDesc'));
  sortingOptions.set(TracksSortingOption.popularityAsc, t('Tracks.SortOption.PopularityAsc'));
  sortingOptions.set(TracksSortingOption.random, t('Tracks.SortOption.Random'));

  const menuItems = Array.from(sortingOptions.keys()).map(option => {
    return <MenuItem
      key={option}
      type="checkbox"
      checked={props.selectedOption === option}
      onClick={() => props.optionSelectionHandler(option)}>
      {sortingOptions.get(option)}
    </MenuItem>;
  });

  return <Menu
    menuButton={
      <MenuButton className="tracks-sorting-menu">
        {sortingOptions.get(props.selectedOption)}
        <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
      </MenuButton>
    }

    theming="dark">
    {menuItems}
  </Menu>;
}