export enum ArtistTracksCount {
    one,
    few,
    many,
    maximum
}

export enum ArtistTracksCollectionMode {
    top,
    discography,
    any
}
