import {IStore} from '../store';
import {IBridgeGuiSettings, IAlbumSelectionSettings, IGuiSettingsStore} from './guiSettings.interface';

export const guiSettingsSelector: (state: IStore) =>
  IGuiSettingsStore = (state: IStore) => state.guiSettings;

export const bridgeGuiSettingsSelector: (state: IStore) =>
  IBridgeGuiSettings = (state: IStore) => guiSettingsSelector(state).bridge;

export const genresExplorerSettingsSelector: (state: IStore) =>
  IAlbumSelectionSettings = (state: IStore) => guiSettingsSelector(state).genreExplorer;

export const labelsExplorerSettingsSelector: (state: IStore) =>
  IAlbumSelectionSettings = (state: IStore) => guiSettingsSelector(state).labelExplorer;