import React from 'react';

import './logo.css';

type LogoProps = {
  iconSize?: number
}

export default function Logo(props: LogoProps): JSX.Element {
  const iconSize = props.iconSize ?? 50;
  return <div className={'app-logo-wrapper'}>
    <img src={'logo-round.jpg'} width={iconSize} height={iconSize} />
    <div className={'app-logo'}>Unchartify</div>
  </div>;
}
