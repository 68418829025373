import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {isMobile} from 'react-device-detect';
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import Lightbox from 'react-image-lightbox';

import SpotifyAlbum from '../spotify/spotifyAlbum';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyArtist from '../spotify/spotifyArtist';
import SpotifyTrack from '../spotify/spotifyTrack';
import {deduplicateArtists, getArtistsFromTracks} from '../spotify/apiHelpers';

import {ArtistTile} from '../artist-tile/artistTile';
import ArtistMenu from '../menus/artist-menu/artistMenu';
import {Tracklist} from '../tracklist/tracklist';
import ArtistMixMenuButton from '../menu-buttons/artist-mix-menu-button/artistMixMenuButton';
import {AlbumsList} from '../albums-list/albumsList';
import ArtistMixTile from '../artist-mix-tile/artistMixTile';
import usePlayArtist from '../hooks/player/usePlayArtist';

import useCollectArtistTracks from '../hooks/artists/useCollectArtistTracks';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../hooks/artists/artistTracksCollectionEnums';

import {track, TrackingEvent} from '../util/track';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';

import 'react-tabs/style/react-tabs.css';
import './artistDetails.css';
import './artistDetailsMobile.css';

type ArtistDetailsProps = {
    artistId: string
}

function ArtistDetails(props: ArtistDetailsProps): JSX.Element {
  const { t } = useTranslation();

  const detailsTabs = {
    albums: t('ArtistDetails.Albums'),
    singles: t('ArtistDetails.Singles'),
    compilations: t('ArtistDetails.Compilations'),
    appearsOn: t('ArtistDetails.AppearsOn'),
    topTracks: t('ArtistDetails.TopTracks'),
    similar: t('ArtistDetails.SimilarArtists')
  };

  const spotify = useContext(SpotifyApiContext);
  const playArtist = usePlayArtist();

  const [artist, setArtist] = useState<SpotifyArtist>();
  const [albums, setAlbums] = useState<SpotifyAlbum[]>([]);
  const [singles, setSingles] = useState<SpotifyAlbum[]>([]);
  const [compilations, setCompilations] = useState<SpotifyAlbum[]>([]);
  const [appearsOn, setAppearsOn] = useState<SpotifyAlbum[]>([]);
  const [similar, setSimilar] = useState<SpotifyArtist[]>([]);
  const [topTracks, setTopTracks] = useState<SpotifyTrack[]>([]);
  const [mobileSelectedTab, setMobileSelectedTab] = useState<string>(detailsTabs.albums);
  const [loading, setLoading] = useState<boolean>(true);
  const [busy, setBusy] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(spotify.canPlay());
  const [showArtistImage, setShowArtistImage] = useState<boolean>(false);

  const collectArtistTracks = useCollectArtistTracks();

  useEffect(() => {
    activateGenericBackground(artist?.images?.[0]?.url);
    return () => deactivateGenericBackground();
  }, [artist]);

  useEffect(() => {
    const setCanPlayHandler = () => setCanPlay(spotify.canPlay());
    spotify.playbackAbilityChange.addEventListener(
      'playbackAbilityChange', setCanPlayHandler);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', setCanPlayHandler);
    };
  }, [spotify]);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      spotify.getArtist(props.artistId),
      spotify.getArtistAlbums(props.artistId),
      spotify.getArtistTopTracks(props.artistId),
      spotify.getSimilarArtists(props.artistId),
      spotify.getArtistRecommendations([props.artistId])
    ]).then(results =>
    {
      setLoading(false);
      const artist = results[0] as SpotifyArtist;
      const releases = results[1] as Map<string, SpotifyAlbum[]>;
      const topTracks = results[2] as SpotifyTrack[];
      const similar = results[3] as SpotifyArtist[];
      const tracks = results[4] as SpotifyTrack[];

      setArtist(artist);
      const albums = releases.get('album') ?? [];
      const singles = releases.get('single') ?? [];
      const compilations = releases.get('compilation') ?? [];
      const appearsOn = releases.get('appears_on') ?? [];
      setAlbums(albums);
      setSingles(singles);
      setCompilations(compilations);
      setAppearsOn(appearsOn);

      if (albums.length !== 0) {
        setMobileSelectedTab(detailsTabs.albums);
      } else if (singles.length !== 0) {
        setMobileSelectedTab(detailsTabs.singles);
      } else if (compilations.length !== 0) {
        setMobileSelectedTab(detailsTabs.compilations);
      } else {
        setMobileSelectedTab(detailsTabs.appearsOn);
      }
      setTopTracks(topTracks);

      const combined = deduplicateArtists([...similar, ...getArtistsFromTracks(tracks)])
        .filter(a => a.id !== artist.id);
      spotify.loadArtistImages(combined).then(() => setSimilar(combined));

      // todo: another stupid hack to auto-select first tab :-(
      (document.getElementsByClassName('react-tabs__tab')[0] as HTMLElement)?.click();

      track(TrackingEvent.screenView, {
        'screen_name': 'Artist Details',
        'item_id': artist.id,
        'item_name': artist.name
      });
    });
  }, [spotify, props.artistId, detailsTabs.albums, detailsTabs.singles, detailsTabs.compilations, detailsTabs.appearsOn]);

  const handlePlayArtist = useCallback(() => artist && playArtist(artist), [artist, playArtist]);

  const addArtistTracksToMix = async (artists: SpotifyArtist[], count: ArtistTracksCount) => {
    setBusy(true);
    // todo: remove mix suffix
    await collectArtistTracks(artists, count, ArtistTracksCollectionMode.top, '');
    setBusy(false);
  };

  const addArtistTracksToMixMobile = async (count: ArtistTracksCount) =>
    await addArtistTracksToMix({
      [detailsTabs.topTracks]: (artist) ? [artist] : [],
      [detailsTabs.similar]: similar
    }[mobileSelectedTab], count);

  const image = (artist?.images.length)
    ? <div className="artist-image"
      style={{backgroundImage: 'url(' + artist.images[0].url + ')'}}
      onClick={() => setShowArtistImage(true)}/>
    : <div className="artist-image no-cover"/>;

  const genres = <div className={'artist-genres'}>
    {artist?.genres.map(genre =>
      <a key={genre} href={`#genres/${genre}`}>{genre}</a>)}
  </div>;

  const getAlbums = (albums: SpotifyAlbum[], displayArtist?: boolean) =>
    <AlbumsList albums={albums} displayYear={true} displayArtist={displayArtist} integratedSorting={false} />;

  const albumsBlocks = (albums.length)
    ? <div className="artist-disco-section artist-albums">{getAlbums(albums)}</div>
    : null;

  const singlesBlocks = (singles.length)
    ? <div className="artist-disco-section artist-singles">{getAlbums(singles)}</div>
    : null;

  const compilationBlocks = (compilations.length)
    ? <div className="artist-disco-section artist-compilations">{getAlbums(compilations)}</div>
    : null;

  const appearsOnBlocks = (appearsOn.length)
    ? <div className="artist-disco-section artist-appearson">{getAlbums(appearsOn, true)}</div>
    : null;

  const similarBlocks = (similar.length)
    ? <div className="artist-disco-section artist-related">
      <ArtistMixTile artists={similar} mixSuffix={artist ? artist.name + '+' : ''} />
      {similar.map(a => <ArtistTile key={'ra' + a.id} artist={a} />)}
    </div>
    : null;

  const artistImageViewer = (artist?.images?.length)
    ? <Lightbox
      mainSrc={artist.images[0].url}
      onCloseRequest={() => setShowArtistImage(false)}/>
    : null;

  const spinner = (loading || busy)
    ? <div className="spinner inline"/>
    : null;

  if (!isMobile) {
    return <div className="artist-details">
      <div className="artist">
        {image}
        <div className="artist-info">
          <div className="artist-name">
            {artist?.name ?? t('Loading...')}
            {artist && <ArtistMenu artist={artist}><span className="artist-menu">⋯</span></ArtistMenu>}
          </div>
          {genres}
        </div>
        <div className="artist-stat">
          <div className="stat-name">{t('ArtistDetails.Popularity')}</div>
          <div className="stat-value">{artist?.popularity ?? '...'}</div>
        </div>
        <div className="artist-stat">
          <div className="stat-name">{t('ArtistDetails.Followers')}</div>
          <div className="stat-value">{artist?.followers ?? '...'}</div>
        </div>
        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayArtist}>
          <img src={'play.svg'} alt={t('ArtistDetails.PlayButtonAlt')}/>
        </div>
      </div>

      <Tabs defaultIndex={0}>
        <TabList>
          {(loading || albums.length > 0) && <Tab>{detailsTabs.albums}</Tab>}
          {(loading || singles.length > 0) && <Tab>{detailsTabs.singles}</Tab>}
          {(loading || compilations.length > 0) && <Tab>{detailsTabs.compilations}</Tab>}
          {(loading || appearsOn.length > 0) && <Tab>{detailsTabs.appearsOn}</Tab>}
          {(loading || topTracks.length > 0) && <Tab>{detailsTabs.topTracks}</Tab>}
          {(loading || similar.length > 0) && <Tab>{detailsTabs.similar}</Tab>}
        </TabList>
        {(loading || albums.length > 0) && <TabPanel>
          {albumsBlocks}
        </TabPanel>}
        {(loading || singles.length > 0) && <TabPanel>
          {singlesBlocks}
        </TabPanel>}
        {(loading || compilations.length > 0) && <TabPanel>
          {compilationBlocks}
        </TabPanel>}
        {(loading || appearsOn.length > 0) && <TabPanel>
          {appearsOnBlocks}
        </TabPanel>}
        {(loading || topTracks.length > 0) && <TabPanel>
          <ArtistMixMenuButton numberOfTracksSelected={(count: ArtistTracksCount) =>
            addArtistTracksToMix((artist) ? [artist] : [], count)} />
          <Tracklist tracks={topTracks} displayCoverArt={true} />
        </TabPanel>}
        {(loading || similar.length > 0) && <TabPanel>
          {similarBlocks}
        </TabPanel>}
      </Tabs>
      {showArtistImage && artistImageViewer}
      {spinner}
    </div>;
  } else {

    const availableTabs = [
      ...((albums.length !== 0) ? [detailsTabs.albums] : []),
      ...((singles.length !== 0) ? [detailsTabs.singles] : []),
      ...((compilations.length !== 0) ? [detailsTabs.compilations] : []),
      ...((appearsOn.length !== 0) ? [detailsTabs.appearsOn] : []),
      ...((topTracks.length !== 0) ? [detailsTabs.topTracks] : []),
      ...((similar.length !== 0) ? [detailsTabs.similar] : [])
    ];

    const tabMenuItems = availableTabs.map(tab => {
      return <MenuItem
        key={tab}
        type="checkbox"
        checked={mobileSelectedTab === tab}
        onClick={() => setMobileSelectedTab(tab)}>
        {tab}
      </MenuItem>;
    });

    return <div className="artist-details">
      <div className="artist-cover">
        {image}
        <div className="artist-info">
          <div className="artist-name">
            {artist?.name ?? t('Loading...')}
          </div>
          {genres}
        </div>
        {artist && <div className="artist-menu"><ArtistMenu artist={artist}>⋯</ArtistMenu></div>}
      </div>
      <div className="artist-stats">
        <div className="artist-stat artist-popularity">
          <div className="stat-name">{t('ArtistDetails.Popularity')}</div>
          <div className="stat-value">{artist?.popularity ?? '...'}</div>
        </div>
        <div className="artist-stat artist-followers">
          <div className="stat-name">{t('ArtistDetails.Followers')}</div>
          <div className="stat-value">{artist?.followers ?? '...'}</div>
        </div>
        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayArtist}>
          <img src={'play.svg'} alt={t('ArtistDetails.PlayButtonAlt')}/>
        </div>
      </div>
      <div className="artist-details-mobile-actions">
        <Menu
          menuButton={
            <MenuButton className="tab-menu-button" disabled={loading}>
              {mobileSelectedTab}
              <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
            </MenuButton>
          }
          className="tab-menu"
          theming="dark">
          {tabMenuItems}
        </Menu>
        {(mobileSelectedTab === detailsTabs.topTracks) &&
                <ArtistMixMenuButton numberOfTracksSelected={addArtistTracksToMixMobile} />}
      </div>
      {(mobileSelectedTab === detailsTabs.albums) && <div className="artist-tab-content">{albumsBlocks}</div>}
      {(mobileSelectedTab === detailsTabs.singles) && <div className="artist-tab-content">{singlesBlocks}</div>}
      {(mobileSelectedTab === detailsTabs.compilations) && <div className="artist-tab-content">{compilationBlocks}</div>}
      {(mobileSelectedTab === detailsTabs.appearsOn) && <div className="artist-tab-content">{appearsOnBlocks}</div>}
      {(mobileSelectedTab === detailsTabs.topTracks) && <div className="artist-tab-content">
        <Tracklist tracks={topTracks} displayCoverArt={true} />
      </div>}
      {(mobileSelectedTab === detailsTabs.similar) && <div className="artist-tab-content">{similarBlocks}</div>}

      {showArtistImage && artistImageViewer}
      {spinner}
    </div>;
  }
}

export default ArtistDetails;
