import React, {FunctionComponent} from 'react';
import {Menu, MenuButton} from '@szhsin/react-menu';
import useAlbumMixMenuItems from '../../menus/album-mix-menu-items/useAlbumMixMenuItems';
import {AlbumTracksCount} from '../../hooks/albums/useCollectAlbumTracks';

type AlbumMixCustomMenuButtonProps = {
    numberOfTracksSelected(count: AlbumTracksCount): void
}

export const AlbumMixCustomMenuButton: FunctionComponent<AlbumMixCustomMenuButtonProps> = ({numberOfTracksSelected, children}
    : React.PropsWithChildren<AlbumMixCustomMenuButtonProps>) => {

  return <Menu
    className="album-mix-menu"
    portal={true}
    arrow={true}
    align="start"
    menuButton={<MenuButton className={'album-menu-button'}>{children}</MenuButton>}
    theming="dark">
    {useAlbumMixMenuItems(numberOfTracksSelected)}
  </Menu>;
};
