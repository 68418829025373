import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {LabelsGroup} from './labelsManager';

import './labelGroup.css';

type LabelGroupProps = {
  group: LabelsGroup;
  last: boolean;
};

const expandedLabelsCount = isMobile ? 5 : 100;

const LabelGroup: (props: LabelGroupProps) => JSX.Element = (props: LabelGroupProps) => {
  const { t } = useTranslation();
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const labels = props.group.labels.map((l) => (
    <div key={`lgc-${l}`}>
      <a key={`lgl-${l}`} href={'#/labels/' + l}>
        {l}
      </a>
    </div>
  ));

  const expandedLabels = !expandAll
    ? labels.slice(0, expandedLabelsCount)
    : labels;

  const collapsedLabels =
    !expandAll && labels.length > expandedLabelsCount ? (
      <span className="group-collapsed-labels">
        {labels.slice(expandedLabelsCount)}
      </span>
    ) : (
      []
    );

  const expandAllLink =
    !expandAll && labels.length > expandedLabelsCount ? (
      <button onClick={() => setExpandAll(true)} className="expand-all">
        <span className="expand-all">
          {t('Show all')} ({labels.length})
        </span>
      </button>
    ) : null;

  const showGroupCaption = !isMobile || props.group.name !== '';
  return (
    <div
      id={'group-anchor-' + props.group.name}
      className={'label-group' + (props.last ? ' last' : '')}
    >
      <div
        className={
          'label-group-container' + (!showGroupCaption ? ' no-caption' : '')
        }
      >
        {showGroupCaption && (
          <div className="label-group-caption">
            <h4>{props.group.name}</h4>
          </div>
        )}
        <div className="group-labels">
          {expandedLabels}
          {collapsedLabels}
          {expandAllLink}
        </div>
      </div>
    </div>
  );
};

export default LabelGroup;
