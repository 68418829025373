import React, {useContext, useEffect, useMemo, useState} from 'react';
import Switch from 'react-switch';
import {useTranslation} from 'react-i18next';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import InfiniteScroll from 'react-infinite-scroll-component';
import SuggestInput from '../suggest-input/suggestInput';
import SpotifyArtist from '../spotify/spotifyArtist';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {track, TrackingEvent} from '../util/track';
import {ArtistTile} from '../artist-tile/artistTile';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import {isMobile} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {followedArtistIdsSelector} from '../redux/artists/artists.selector';
import {ArtistSuggestionsLoader} from './artistSuggestionsLoader';
import {permissionsSelector} from '../redux/permissions/permissions.selector';

import './artistBrowser.css';

enum ArtistSelectionMode {
  Followed = 'followed',
  Any = 'any'
}

const searchSpotifyArtists = AwesomeDebouncePromise((loader: ArtistSuggestionsLoader, query: string) => {
  loader.init(query);
  return loader.nextSuggestions();
}, 500);

const ArtistBrowser: () => JSX.Element = () => {

  const { t } = useTranslation();
  const spotifyApi = useContext(SpotifyApiContext);
  const suggestionsLoader: ArtistSuggestionsLoader = useMemo(() => {
    return new ArtistSuggestionsLoader(spotifyApi);
  }, [spotifyApi]);

  const permissions = useSelector(permissionsSelector);

  const [filter, setFilter] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SpotifyArtist[]>([]);
  const [selectionMode, setSelectionMode] = useState<ArtistSelectionMode>(
    (permissions.hasLibrary) ? ArtistSelectionMode.Followed : ArtistSelectionMode.Any);
  const [loading, setLoading] = useState<boolean>(true);
  const [followedArtists, setFollowedArtists] = useState<SpotifyArtist[]>([]);

  const followedArtistIds = useSelector(followedArtistIdsSelector);

  const isFollowedMode = selectionMode === ArtistSelectionMode.Followed;
  const isFiltered = filter !== '';

  const suggestFollowedArtists = (value: string) : SpotifyArtist[] => {
    return followedArtists.slice().filter(artist =>
      artist.name.toLocaleLowerCase().match(value.toLocaleLowerCase()));
  };

  const suggestAnyArtists = async (query: string): Promise<SpotifyArtist[]> => {
    if (query.trim().length < 2) {
      return [];
    }
    setLoading(true);
    const result = await searchSpotifyArtists(suggestionsLoader, query);
    setLoading(false);
    return result;
  };

  useEffect(() => {
    if (!isFollowedMode) {
      return;
    }
    setLoading(true);
    spotifyApi
      .getUserFollowedArtists()
      .then((artists: Array<SpotifyArtist>) => {
        setFollowedArtists(artists);
        if (artists.length) {
          setSuggestions(artists);
        } else {
          setSelectionMode(ArtistSelectionMode.Any);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isFollowedMode, selectionMode, spotifyApi]);

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    setLoading(true);
    track(TrackingEvent.search, {
      'search_term': filter
    });
    if (selectionMode === ArtistSelectionMode.Followed) {
      setSuggestions(suggestFollowedArtists(filter));
      setLoading(false);
    } else {
      suggestAnyArtists(filter).then((suggestions: SpotifyArtist[]) => {
        setSuggestions(suggestions);
        setLoading(false);
      });
    }
  }, [followedArtists, filter, isFiltered, selectionMode]);

  const changeSelectionMode = (checked: boolean) => {
    setSelectionMode(checked ? ArtistSelectionMode.Followed : ArtistSelectionMode.Any);
  };

  const filterArtists = (filter: string) => {
    setFilter((filter.length) > 1 ? filter : '');
  };

  const loadSuggestions = async () => {
    if (isFollowedMode || suggestionsLoader.isLoading()) {
      return;
    }
    setLoading(true);
    if (suggestionsLoader.hasMoreSuggestions()) {
      const newSuggestions = await suggestionsLoader.nextSuggestions();
      setSuggestions([...suggestions, ...newSuggestions]);
      setLoading(false);
    }
  };

  const suggestedArtists = suggestions
    .filter(artist => !isFollowedMode || followedArtistIds.includes(artist.id))
    .map(artist => <ArtistTile key={artist.id} artist={artist} />);

  const loader = loading ? <div className="spinner"/> : null;

  return <div className="artist-browser">
    <div className="selection-options">
      <SuggestInput placeholder={t('Type artist name')} value={filter} valueChangeHandler={filterArtists}/>
      {permissions.hasLibrary &&
          <div className="followed-only">
            <Switch
              disabled={followedArtistIds.length === 0}
              onChange={changeSelectionMode}
              checked={selectionMode === ArtistSelectionMode.Followed}
              className="selection-mode"
              onColor="#036551"
              offColor="#036551"
              onHandleColor="#ffffff"
              offHandleColor="#036551"
              handleDiameter={isMobile ? 26 : 36}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={isMobile ? 20 : 30}
              width={isMobile ? 48 : 58}
            />
            <div className="selection-label">
              {t('Followed only')}
            </div>
          </div>
      }
    </div>

    <div className="artist-suggestions">
      <InfiniteScroll
        dataLength={suggestedArtists.length}
        next={loadSuggestions}
        hasMore={true}
        loader={null}
        className="scroll-container">

        {suggestedArtists}
      </InfiniteScroll>
    </div>
    {loader}
  </div>;
};

export default ArtistBrowser;
