import React, {useCallback, useEffect, useState} from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';

import './suggestInput.css';

type SuggestInputProps = {
  placeholder: string
  value: string
  valueChangeHandler(value: string): void
  submitHandler?(value: string): void
}

const handleValueChange = AwesomeDebouncePromise((e: React.ChangeEvent<HTMLInputElement>, props: SuggestInputProps) => {
  e.preventDefault();
  props.valueChangeHandler(e.target.value);
}, 300);

const SuggestInput: (props: SuggestInputProps) => JSX.Element = (props: SuggestInputProps) => {
  const {t} = useTranslation();
  const [value, setValue] = useState<string>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onSubmit = useCallback((e) => {
    if (props.submitHandler && e.key === 'Enter') {
      props.submitHandler(e.target.value);
    }
  }, []);

  return <input
    className="suggest-input"
    placeholder={t(props.placeholder)}
    maxLength={50}
    autoFocus={!isMobile}
    value={value}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      handleValueChange(e, props);
    }}
    onKeyPress={onSubmit}
  />;
};

export default SuggestInput;
