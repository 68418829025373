import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import SpotifyAlbum from '../spotify/spotifyAlbum';
import {AlbumMixCustomMenuButton} from '../menu-buttons/album-mix-custom-menu-button/albumMixCustomMenuButton';
import useCollectAlbumTracks, {AlbumTracksCount} from '../hooks/albums/useCollectAlbumTracks';

import  './addToMixTile.css';

type AlbumMixTileProps = {
    albums: SpotifyAlbum[]
    caption?: string,
    mixSuffix?: string
}

export default function AlbumMixTile(props: AlbumMixTileProps): JSX.Element {

  const {t} = useTranslation();
  const [busy, setBusy] = useState<boolean>(false);
  const collectAlbumsTracks = useCollectAlbumTracks();

  const caption = props.caption ?? t('AlbumMenu.CollectTracks');
  const mixSuffix = props.mixSuffix ?? '';

  const addToMix = async (count: AlbumTracksCount) => {
    setBusy(true);
    await collectAlbumsTracks(props.albums, count, mixSuffix);
    setBusy(false);
  };

  return <div className="album-mix-tile">
    <AlbumMixCustomMenuButton numberOfTracksSelected={addToMix}>
      <div>
        <div className="album-mix-tile-image">
          <img src={'playlist.svg'} alt={caption}/>
        </div>
        <span className="album-mix-caption">{caption}</span>
      </div>
    </AlbumMixCustomMenuButton>
    {busy && <div className="spinner inline"/>}
  </div>;
}
