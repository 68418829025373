import i18n from 'i18next';

function _getFractions(duration: number) {
  const durationInSeconds = duration / 1000;
  const h = Math.floor(durationInSeconds / 3600);
  const m = Math.floor(durationInSeconds % 3600 / 60);
  const s = Math.floor(durationInSeconds % 3600 % 60);
  return [h, m, s];
}

export function getFriendlyDuration(duration: number, withSeconds = false, shortFormat = false): string {
  const [h, m, s] = _getFractions(duration);

  const tH = i18n.t(shortFormat ? 'FriendlyDuration.H' : 'FriendlyDuration.Hours', {count: h});
  const tM = i18n.t(shortFormat ? 'FriendlyDuration.M' : 'FriendlyDuration.Minutes', {count: m});
  const tS = i18n.t(shortFormat ? 'FriendlyDuration.S' : 'FriendlyDuration.Seconds', {count: s});

  const hDisplay = h > 0 ? tH + ' ' : '';
  const mDisplay = m > 0 ? tM + ' ' : '';
  const sDisplay = s > 0 ? tS + ' ' : '';

  return hDisplay + mDisplay + ((withSeconds) ? sDisplay : '');
}

export function getFormattedDuration(duration: number): string {
  const [h, m, s] = _getFractions(duration);
  const hDisplay = h > 0 ? h + ':' : '';
  const sDisplay = ('00' + s).substr(-2, 2);
  return hDisplay + m + ':' + sDisplay;
}



