import React from 'react';
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

import PlayerSelector from '../player-selector/playerSelector';
import SocialLinks from '../social/socialLinks';
import {permissionsSelector} from '../redux/permissions/permissions.selector';
import {isMobile} from 'react-device-detect';
import {nowPlayingSelector} from '../redux/player/player.selector';

import './footer.css';

interface AppFooterProps {
  isLargeScreenMode?: boolean;
}

export default function AppFooter({ isLargeScreenMode }: AppFooterProps): JSX.Element {
  const { t } = useTranslation();
  const permissions = useSelector(permissionsSelector);
  const nowPlaying = useSelector(nowPlayingSelector);

  if (isMobile && (!nowPlaying?.track ?? false)) {
    return <></>;
  }
  return (
    <div className={`app-footer ${isLargeScreenMode ? 'app-footer-hidden' : ''}`}>
      {permissions.canViewNowPlaying && <PlayerSelector />}
      {!isMobile && <div className='app-footer-legal'>{t('Footer.Copyright')}</div>}
      {!isMobile && <SocialLinks size={30} />}
    </div>
  );
}
