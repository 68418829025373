import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Menu, MenuItem} from '@szhsin/react-menu';
import SpotifyPlayer from '../spotify/spotifyPlayer';
import SpotifyApiContext from '../spotify/spotifyApiContext';

import {useSelector} from 'react-redux';
import {nowPlayingSelector} from '../redux/player/player.selector';
import useUpdateNowPlaying from '../hooks/player/useUpdateNowPlaying';
import TrackMenu from '../menus/track-menu/trackMenu';
import ArtistMenu from '../menus/artist-menu/artistMenu';
import AlbumMenu from '../menus/album-menu/albumMenu';
import {isMobile} from 'react-device-detect';

import './playerSelector.css';

const PlayerSelector: () => JSX.Element = () => {
  const { t } = useTranslation();
  const spotify = useContext(SpotifyApiContext);
  const nowPlaying = useSelector(nowPlayingSelector);
  const updateNowPlaying = useUpdateNowPlaying();

  const players: SpotifyPlayer[] = spotify.getUserPlayers();
  const [activePlayer, setActivePlayer] = useState<SpotifyPlayer|null>(players[0] ?? null);

  const onPlaybackAbilityChange = () => {
    setActivePlayer(spotify.canPlay() ? spotify.getUserCurrentPlayer() : null);
  };

  useEffect(() => {
    spotify.playbackAbilityChange.addEventListener(
      'playbackAbilityChange', onPlaybackAbilityChange);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', onPlaybackAbilityChange);
    };
  }, []);

  const selectPlayer = (player: SpotifyPlayer) => {
    spotify.switchUserPlayer(player.id).then(() => {
      setActivePlayer(players.find(p => p.id === player.id) ?? null);
    });
  };

  const ensureNowPlayingUpdate = () => {
    setTimeout(updateNowPlaying, 500);
  };

  const detectPlayers = () => {
    spotify.detectDevices().then();
  };

  const pause = () => {
    spotify.pause().then(ensureNowPlayingUpdate);
  };

  const play = () => {
    spotify.play().then(ensureNowPlayingUpdate);
  };

  const skipToNext = () => {
    spotify.skipToNext().then(ensureNowPlayingUpdate);
  };

  const skipToPrevious = () => {
    spotify.skipToPrevious().then(ensureNowPlayingUpdate);
  };

  const items = players.map(p =>
    <MenuItem
      key={p.id}
      type="checkbox"
      checked={p.id === activePlayer?.id}
      onClick={() => selectPlayer(p)}>{p.name}
    </MenuItem>);

  if (!items.length) {
    items.push(<MenuItem key="p-detect" onClick={detectPlayers}>{t('Players.Detect')}</MenuItem>);
  }

  const hasTrack = !!nowPlaying?.track;
  const isPlaying = nowPlaying?.isPlaying ?? false;

  return (
    <div className="player-select">
      <div className="player-controls">
        {hasTrack && <>
          <a onClick={skipToPrevious}><img className="pc-rewind" src={'pc-rewind.svg'} alt={t('Players.Rewind')}/></a>
          {!isPlaying && <a onClick={play}><img className="pc-play" src={'pc-play.svg'} alt={t('Players.Play')}/></a>}
          {isPlaying && <a onClick={pause}><img className="pc-pause" src={'pc-pause.svg'} alt={t('Players.Pause')}/></a>}
          <a onClick={skipToNext}><img className="pc-forward" src={'pc-forward.svg'} alt={t('Players.Forward')}/></a>
        </>}

        {!isMobile && !activePlayer && <span>
          {t('Players.NotFound1')}<br/>{t('Players.NotFound2')} <strong>{t('Players.NotFound3')}</strong>
        </span>}

        {!isMobile &&
          <Menu
            className="players-menu"
            portal={true}
            arrow={true}
            align="end"
            menuButton={<div className="player-select-menu">
              <img className="icon-device" src={activePlayer ? 'devices.svg' : 'devices-none.svg'} alt={t('Players.DeviceIcon')}/>
              {
                !hasTrack && activePlayer?.name ?
                  <span className="player-name">{activePlayer?.name}</span>
                  :
                  <></>
              }
              <img className="icon-arrow" src={activePlayer ? 'up-arrow.svg' : 'up-arrow-orange.svg'} alt={t('Players.MenuButton')}/>
            </div>
            }
            theming="dark">
            {items}
          </Menu>
        }
      </div>

      {nowPlaying && <>
        <div className="player-item">
          {nowPlaying.track.album && <AlbumMenu album={nowPlaying.track.album}>
            <div className="player-item-artwork" style={{backgroundImage: 'url(' + ( nowPlaying.track.album?.images?.[2].url ?? '') + ')'}} />
          </AlbumMenu>}
          <div className="player-item-info">
            <TrackMenu track={nowPlaying.track}>
              <span className="now-playing-track">{nowPlaying?.track.name}</span>
            </TrackMenu>
            <ArtistMenu artist={nowPlaying.track.artists[0]}>
              <span className="now-playing-artist">{nowPlaying.track.artists[0].name}</span>
            </ArtistMenu>
          </div>
        </div>
      </>}

    </div>
  );
};

export default PlayerSelector;
