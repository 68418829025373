import Api from '../spotify/api';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import QueryProgress from './queryProgress';

type QueryBuilderType = (query: string) => string;
type AlbumSelectionLoaderType = (query: string, limit: number, offset: number, exactMatch?: boolean) =>
  Promise<[SpotifyAlbum[], boolean]>;

export class AlbumSelectionLoader {

  static readonly defaultFromYear = (new Date()).getFullYear();
  static readonly defaultToYear = 1900;
  static readonly maxEmptyYears = 20;
  static readonly defaultBatchSize = 100;

  private api: Api;

  private progress: QueryProgress[] = [];

  private batchSize = AlbumSelectionLoader.defaultBatchSize;
  private includeAlbums = true;
  private includeSingles = true;
  private includeVA = true;
  private fromYear = AlbumSelectionLoader.defaultFromYear;
  private toYear = AlbumSelectionLoader.defaultToYear;
  private yearStep = -1;
  private exactMatch = false;

  private readonly queryBuilder: QueryBuilderType;
  private readonly albumsLoader: AlbumSelectionLoaderType;

  private limit = 50;
  private currentYear: number = this.fromYear;
  private emptyYearsCount = 0;
  private processedAlbums = new Map<string, true>();
  private _hasMoreAlbums = false;
  private _isLoading = false;

  constructor(spotifyApi: Api, queryBuilder: QueryBuilderType, albumsLoader: AlbumSelectionLoaderType) {
    this.api = spotifyApi;
    this.queryBuilder = queryBuilder;
    this.albumsLoader = albumsLoader;
  }

  init(
    queries: string[],
    includeAlbums = true,
    includeSingles = true,
    includeVA = true,
    fromYear: number = AlbumSelectionLoader.defaultFromYear,
    toYear: number = AlbumSelectionLoader.defaultToYear,
    exactMatch = false,
    batchSize: number = AlbumSelectionLoader.defaultBatchSize,
  ): void {
    this.emptyYearsCount = 0;
    this.progress = queries.map(q => new QueryProgress(q));

    this.includeAlbums = includeAlbums;
    this.includeSingles = includeSingles;
    this.includeVA = includeVA;

    this.fromYear = (fromYear != null && fromYear !== 0 && fromYear <= AlbumSelectionLoader.defaultFromYear)
      ? fromYear
      : AlbumSelectionLoader.defaultFromYear;

    this.toYear = (toYear != null && toYear !== 0 && toYear >= AlbumSelectionLoader.defaultToYear)
      ? toYear
      : AlbumSelectionLoader.defaultToYear;

    this.exactMatch = exactMatch;

    this.batchSize = batchSize ?? AlbumSelectionLoader.defaultBatchSize;

    this.yearStep = (this.fromYear > this.toYear) ? -1 : 1;
    this.currentYear = this.fromYear;

    this.processedAlbums = new Map<string, true>();
    this._hasMoreAlbums = true;
  }

  async nextAlbums() : Promise<SpotifyAlbum[]> {
    this._isLoading = true;
    const albums = new Map<string, SpotifyAlbum>();

    while (albums.size < this.batchSize) {
      const progressingQueries = this.progress.filter(g => !g.isDone);
      if (progressingQueries.length === 0) {
        const isEmptyYear = this.progress.reduce<number>((total: number, g: QueryProgress) => total + g.offset, 0) === 0;
        if (isEmptyYear) {
          this.emptyYearsCount++;
        }
        if (!this.nextYear()) {
          break;
        }
      }
      for (const progress of progressingQueries) {
        const query = `${this.queryBuilder(progress.query)} year:${this.currentYear}`;
        try {
          const [rawAlbums, hasNext] = await this.albumsLoader(query, this.limit, progress.offset, this.exactMatch);
          for (const album of rawAlbums) {
            if (!this.processedAlbums.has(album.id)) {
              // album type filter
              const isVA = album.artists.length === 1 && album.artists[0].name.toLowerCase() === 'various artists';
              const excludeAlbum = !this.includeAlbums && album.type === 'album' && !isVA;
              const excludeSingle = !this.includeSingles && album.type === 'single';
              const excludeVA = !this.includeVA && ((album.type === 'compilation') || isVA);
              if (excludeAlbum || excludeSingle || excludeVA) {
                continue;
              }
              const albumHash = album.artists.map(a => a.id).join('|') +
                album.name + '|' + album.type + '|' + album.releaseDate;

              if (!this.processedAlbums.has(albumHash)) {
                albums.set(album.id, album);
                this.processedAlbums.set(album.id, true);
                this.processedAlbums.set(albumHash, true);
              }
            }
          }
          if (!hasNext) {
            progress.isDone = true;
          } else {
            progress.offset += this.limit;
          }
        } catch {
          progress.isDone = true;
        }
        if (albums.size >= this.batchSize) {
          break;
        }
      }
    }
    this._isLoading = false;
    return Array.from(albums.values()).sort((a: SpotifyAlbum, b: SpotifyAlbum) => {
      if (!a.releaseDate || !b.releaseDate) {
        return 0;
      }
      const d1 = Date.parse(a.releaseDate);
      const d2 = Date.parse(b.releaseDate);
      if (d2 > d1) {
        return -this.yearStep;
      } else if (d2 < d1) {
        return this.yearStep;
      }
      return 0;
    });
  }

  hasMoreAlbums(): boolean {
    return this._hasMoreAlbums;
  }

  isLoading(): boolean {
    return this._isLoading;
  }

  private nextYear(): boolean {
    this.progress.forEach(g => {
      g.isDone = false;
      g.offset = 0;
    });
    this._hasMoreAlbums = this.currentYear !== this.toYear && this.emptyYearsCount < AlbumSelectionLoader.maxEmptyYears;
    this.currentYear += this.yearStep;
    return this._hasMoreAlbums;
  }
}
