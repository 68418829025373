import {useContext} from 'react';
import SpotifyTrack from '../../spotify/spotifyTrack';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import useUpdateNowPlaying from './useUpdateNowPlaying';

function usePlayTracks(): (tracks: SpotifyTrack[]) => void {
  const api = useContext(SpotifyApiContext);
  const updateNowPlaying = useUpdateNowPlaying();

  return async (tracks: SpotifyTrack[]) => {
    if (tracks && tracks.length && api.canPlay()) {
      api.playTracks(tracks).then(() => setTimeout(updateNowPlaying, 500));
    }
  };
}

export default usePlayTracks;
