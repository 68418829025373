import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import {isMobile} from 'react-device-detect';

import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyTrack from '../spotify/spotifyTrack';
import usePlayTrack from '../hooks/player/usePlayTrack';

import TrackMenu from '../menus/track-menu/trackMenu';
import {Popularity} from '../popularity/popularity';
import {getFormattedDuration} from '../util/getFriendlyDuration';
import ServerApiContext from '../server-api/serverApiContext';

import './tracklistEntry.css';

type TracklistEntryProps = {
  track: SpotifyTrack,
  number?: number,
  displayCoverArt?: boolean,
  displayPopularity?: boolean,
  nowPlaying: boolean
}

function WrappedTracklistEntry(props: TracklistEntryProps): JSX.Element {
  const spotify = useContext(SpotifyApiContext);
  const serverApi = useContext(ServerApiContext);
  const {t} = useTranslation();
  const playTrack = usePlayTrack();

  const track = props.track;
  const number = props.number ?? track.number;
  const displayCoverArt = props.displayCoverArt ?? false;
  const nowPlaying = props.nowPlaying ?? false;

  const [canPlay, setCanPlay] = useState<boolean>(spotify.canPlay());
  const [showCoverImage, setShowCoverImage] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<string|undefined>();
  const [menuEnabled, setMenuEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showCoverArt = async () => {
    if (track && track.album) {
      if (!coverImage) {
        setLoading(true);
        const amCover = await serverApi.getCoverArt(track.album.artists[0].name, track.album.name);
        setCoverImage(amCover ? amCover.url : track.album.images?.[0].url);
        setLoading(false);
      }
      setShowCoverImage(true);
    }
  };

  const handlePlay = useCallback(() => playTrack(track), [track, playTrack]);

  useEffect(() => {
    const setCanPlayHandler = () => setCanPlay(spotify.canPlay());
    spotify.playbackAbilityChange.addEventListener('playbackAbilityChange', setCanPlayHandler);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', setCanPlayHandler);
    };
  }, [spotify]);

  const artists = (track.artists.length < 4)
    ? track.artists.map(a => a.name).join(', ')
    : track.artists.slice(0, 3).map(a => a.name).join(', ') + '... +' + (track.artists.length - 3) + ' more';

  const coverArtPreviewUrl = track.album?.images?.[2]?.url;
  const coverArt = (displayCoverArt && coverArtPreviewUrl)
    ? <div
      onClick={showCoverArt}
      className="tracklist-cover-art-image"
      style={{backgroundImage: 'url(' + coverArtPreviewUrl + ')'}}
    />
    : '';

  const coverArtImageViewer = (showCoverImage && coverImage)
    ? <Lightbox
      mainSrc={coverImage}
      onCloseRequest={() => setShowCoverImage(false)}/>
    : null;

  const playIcon = (!isMobile)
    ? (!nowPlaying
      ? <span className={'tracklist-entry-play play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlay}>
        <img src={'play.svg'} alt={t('AlbumDetails.PlayButtonAlt')}/>
      </span>
      : <span className={'tracklist-entry-play play-anima'}>
        <img src={'now-playing.gif'} alt={t('AlbumDetails.PlayButtonAlt')}/>
      </span>)
    : null;

  const spinner = (loading)
    ? <div className='spinner inline'/>
    : null;

  return <>
    {!isMobile && <span className="tracklist-entry-number">{number}</span>}
    {!isMobile && <span className="tracklist-entry-artists">{artists}</span>}
    {!isMobile && <span className="tracklist-entry-name">
      {coverArt}
      <div onClick={handlePlay}>
        {track.name}
      </div>
    </span>}
    {isMobile && <div className="tracklist-entry-mobile-row">
      <div>{coverArt}</div>
      <div onClick={handlePlay}>
        <span className="tracklist-entry-artists">{artists}</span>
        <span className="tracklist-entry-name">{track.name}</span>
      </div>
    </div>}
    <span className="tracklist-entry-duration">{getFormattedDuration(track.duration)}</span>
    <span className="tracklist-entry-popularity">{!!track.popularity && <Popularity value={track.popularity} />}</span>
    {playIcon}
    <span className="tracklist-entry-menu" onMouseEnter={() => setMenuEnabled(true)}>
      {menuEnabled && <TrackMenu track={track}>
                ⋯
      </TrackMenu>}
      {!menuEnabled && <span className={'tracklist-entry-menu-stub'}>⋯</span>}
    </span>
    {coverArtImageViewer}
    {spinner}
  </>;
}

export const TracklistEntry = React.memo(WrappedTracklistEntry);
