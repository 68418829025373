import SpotifyArtist from './spotifyArtist';
import SpotifyTrack from './spotifyTrack';

export function deduplicateArtists(artists: SpotifyArtist[]): SpotifyArtist[] {
  const artistsCache = new Map<string, SpotifyArtist>();
  artists.forEach(a => artistsCache.set(a.id, a));
  return Array.from(artistsCache.values());
}

export function getArtistsFromTracks(tracks: SpotifyTrack[]): SpotifyArtist[] {
  return tracks
    .map(track => track.artists)
    .reduce((acc: SpotifyArtist[], value: SpotifyArtist[]) => [...acc, ...value], []);
}
