import {useContext, useState} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../redux/store';
import {albumsStoreSlice} from '../../redux/albums/albums.slice';

function useRemoveAlbum(): [(id: string) => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();
  const removeAlbum = (albumId: string) => {
    setIsLoading(true);

    return api
      .removeAlbumsFromLibrary([albumId])
      .then(() => {
        dispatch(albumsStoreSlice.actions.removeSavedAlbumIds([albumId]));

        toast(t('AlbumMenu.RemoveToast'), {
          type: 'success',
          position: 'top-center'
        });
      })
      .catch(() => {
        toast(t('AlbumMenu.RemoveToastErr'), {
          type: 'error',
          position: 'top-center'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [removeAlbum, isLoading];
}

export default useRemoveAlbum;
