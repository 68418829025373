import {useContext} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import useUpdateNowPlaying from './useUpdateNowPlaying';
import SpotifyAlbum from '../../spotify/spotifyAlbum';

function usePlayAlbum(): (album: SpotifyAlbum) => void {
  const api = useContext(SpotifyApiContext);
  const updateNowPlaying = useUpdateNowPlaying();

  return async (album: SpotifyAlbum) => {
    if (album && api.canPlay()) {
      api.playAlbum(album).then(() => setTimeout(updateNowPlaying, 500));
    }
  };
}

export default usePlayAlbum;
