import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {permissionsStoreInitialState} from './permissions.constants';
import {SubscriptionType} from '../../spotify/api';

type SetPermissionsPayload = {
  subscription: SubscriptionType,
  canPlayNow: boolean
}

export const permissionsStoreSlice = createSlice({
  name: 'permissions',
  initialState: permissionsStoreInitialState,
  reducers: {
    setPermissions(store, action: PayloadAction<SetPermissionsPayload>) {
      const {subscription, canPlayNow} = action.payload;
      const isSubscriber = subscription !== SubscriptionType.guest;
      const isPremium = subscription === SubscriptionType.free;

      store.isSubscriber = isSubscriber;          // Free or Premium but not guest
      store.canViewProfile = isSubscriber;
      store.canPlay = isPremium;                  // can select what to play on Premium only
      store.canPlayNow = canPlayNow;
      store.canViewNowPlaying = isSubscriber;
      store.canCollectTracks = true;              // can always collect but not create playlists
      store.hasLibrary = isSubscriber;
      store.canManageLibrary = isSubscriber;
      store.canOpenSpotifyLinks = true;           // well, why not?
      store.hasPersonalization = isSubscriber;
      store.canManagePlaylists = isSubscriber;
    }
  }
});
