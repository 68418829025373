import {useContext, useState} from 'react';
import {useAppDispatch} from '../../redux/store';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {albumsStoreSlice} from '../../redux/albums/albums.slice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

function useInitSavedAlbums(): [() => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const api = useContext(SpotifyApiContext);

  const initSavedAlbums = () => {
    setIsLoading(true);

    return api
      .getUserSavedAlbums()
      .then(albums => {
        const albumIds = albums.map(({id}) => id);

        dispatch(albumsStoreSlice.actions.addSavedAlbumIds(albumIds));
      })
      .catch((error) => {
        console.error('useInitSavedAlbums error', error);

        toast(t('Albums.InitErr'), {
          type: 'error',
          position: 'top-center',
          autoClose: 6000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [initSavedAlbums, isLoading];
}

export default useInitSavedAlbums;
