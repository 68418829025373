import {useContext} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

import SpotifyApiContext from '../../spotify/spotifyApiContext';

import useCollectTracks from '../your-mix/useCollectTracks';
import {arrayShuffle} from '../../util/arrayShuffle';
import SpotifyAlbum from '../../spotify/spotifyAlbum';
import arrayChunk from '../../util/arrayChunk';
import {useAppDispatch} from '../../redux/store';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';
import {persistYourMix} from '../../redux/your-mix/yourMix.actions';

export enum AlbumTracksCount {
    one,
    few,
    many,
    all
}

function useCollectAlbumTracks(): (albums: SpotifyAlbum[], count: AlbumTracksCount, mixSuffix: string) => Promise<void> {
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();
  const [collectTracks] = useCollectTracks();

  return async (albums: SpotifyAlbum[], count: AlbumTracksCount, mixSuffix: string) => {
    try {
      let total = 0;

      const albumsWithTracks: SpotifyAlbum[] = [];
      const albumsToLoad: SpotifyAlbum[] = [];
      for (const album of albums) {
        if (album.tracks.length) {
          albumsWithTracks.push(album);
        } else {
          albumsToLoad.push(album);
        }
      }

      // re-download the albums without tracks information (e.g. from search results)
      if (albumsToLoad.length) {
        albumsWithTracks.push(...await api.getAlbums(albumsToLoad.map(a => a.id)));
      }

      const tracksToLoad: string[] = [];
      for (const album of albumsWithTracks) {
        let selection = album.tracks;
        if (count !== AlbumTracksCount.all) {
          let numberOfTracks = 1;
          if (count === AlbumTracksCount.few) {
            numberOfTracks = Math.floor(Math.random() * 2) + 2;
          } else if (count === AlbumTracksCount.many) {
            numberOfTracks = Math.floor(Math.random() * 4) + 4;
          }
          selection = arrayShuffle(selection.splice(0, numberOfTracks));
        }
        if (selection[0].album) {
          // immediately collect tracks with available info
          collectTracks(selection);
          total += selection.length;
        } else {
          // collect for re-downloading
          tracksToLoad.push(...selection.map(t => t.id));
        }
      }

      // re-download tracks with missing info
      if (tracksToLoad.length) {
        const chunks = arrayChunk(tracksToLoad, 50);
        for (const chunk of chunks) {
          const tracks = await api.getTracks(chunk);
          collectTracks(tracks);
          total += tracks.length;
        }
      }
      if (mixSuffix) {
        dispatch(yourMixStoreSlice.actions.setMixSuffix(mixSuffix));
      } else {
        dispatch(yourMixStoreSlice.actions.resetMixSuffix());
      }
      dispatch(persistYourMix());
      if (total > 1) {
        toast(t('TracksCollector.CollectionSucceeded', {count: total}), {
          type: 'success',
          position: 'top-center'
        });
      }
    } catch (error) {
      toast(t('TracksCollector.CollectionFailed'), {
        type: 'error',
        position: 'top-center'
      });
      console.log(error);
    }
  };
}

export default useCollectAlbumTracks;
