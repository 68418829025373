import dagre from 'dagre';
import {FlowElement, isNode, Position} from 'react-flow-renderer';

export function getLayoutElements(
  dagreGraph: dagre.graphlib.Graph,
  elements: FlowElement[],
  direction = 'LR',
  nodeWidth = 200,
  nodeHeight = 50,
  nodeSeparator = 10,
  initialOffsetX = 0,
  initialOffsetY = 0
) : FlowElement[] {

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, align: 'UL', nodesep: nodeSeparator});
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {width: nodeWidth, height: nodeHeight});
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? Position.Left : Position.Top;
      el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
      // unfortunately we need this little hack to pass a slightly different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000 - initialOffsetX,
        y: nodeWithPosition.y + Math.random() / 1000 - initialOffsetY,
      };
    }
    return el;
  });
}
