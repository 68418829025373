import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import i18next from 'i18next';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';

// zero state illustrations
import { ReactComponent as ZeroStateGraphRu } from './img/zeroState-graph-ru.svg';
import { ReactComponent as ZeroStateItemRu } from './img/zeroState-item-ru.svg';
import { ReactComponent as ZeroStateGraphEn } from './img/zeroState-graph-en.svg';
import { ReactComponent as ZeroStateItemEn } from './img/zeroState-item-en.svg';

import './yourMixZeroState.css';
import './yourMixZeroStateMobile.css';

export default function YourMixZeroState(): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);
  
  return (
    <div className="tracks-collector-zero">
      <div className="tracks-collector-zero-title">
        {t('TracksCollector.Zero.Title')}
      </div>
      <div className='tracks-collector-zero-desc'>
        {t('TracksCollector.Zero.Subtitle')}
      </div>
      {!isMobile && (
        <div className="tracks-collector-zero-promo">
          <div className="tracks-collector-zero-images zero-image-1">
            <p>{t('TracksCollector.Zero.Promo1')}</p>
            {i18next.language === 'ru' ? (
              <ZeroStateGraphRu />
            ) : (
              <ZeroStateGraphEn />
            )}
          </div>
          <div className="tracks-collector-zero-images zero-image-2">
            <p>{t('TracksCollector.Zero.Promo2')}</p>
            {i18next.language === 'ru' ? (
              <ZeroStateItemRu />
            ) : (
              <ZeroStateItemEn />
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="mobile tracks-collector-zero-promo">
          <div className="mobile tracks-collector-zero-images zero-image-1">
            <p>{t('TracksCollector.Zero.Promo1')}</p>
            {i18next.language === 'ru' ? (
              <ZeroStateGraphRu />
            ) : (
              <ZeroStateGraphEn />
            )}
          </div>
          <div className="mobile tracks-collector-zero-images zero-image-2">
            <p>{t('TracksCollector.Zero.Promo2')}</p>
            {i18next.language === 'ru' ? (
              <ZeroStateItemRu />
            ) : (
              <ZeroStateItemEn />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
