import Api from '../spotify/api';
import SpotifyAlbum from '../spotify/spotifyAlbum';

export class AlbumsSuggestionsLoader {

  private api: Api;

  private query = '';

  private offset = 0;

  private loading = false;

  private moreSuggestions = true;

  private processedSuggestions = new Map<string, true>();

  constructor(spotifyApi: Api) {
    this.api = spotifyApi;
  }

  init(query: string): void {
    this.query = query;
    this.processedSuggestions = new Map<string, true>();
    this.offset = 0;
    this.moreSuggestions = true;
  }

  async nextSuggestions() : Promise<SpotifyAlbum[]> {
    this.loading = true;
    const result: SpotifyAlbum[] = [];
    const [suggestions] = await this.api.searchAlbums(this.query, this.offset, this.offset + Api.albumsChunkSize*2);
    for (const suggestion of suggestions) {
      if (!this.processedSuggestions.get(suggestion.id)) {
        result.push(suggestion);
        this.processedSuggestions.set(suggestion.id, true);
      }
    }
    if (!result.length) {
      this.moreSuggestions = false;
    }
    this.offset += Api.albumsChunkSize*2;
    this.loading = false;
    return result;
  }

  isLoading = () => this.loading;

  hasMoreSuggestions = () => this.moreSuggestions;
}
