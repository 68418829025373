import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import i18n from 'i18next';

import SocialLinks from '../social/socialLinks';
import { track, TrackingEvent } from '../util/track';
import Logo from '../logo/logo';

import './landing.css';
import './landingMobile.css';

type LandingProps = {
  authRedirectUrl: string;
};

function _featuresPopup(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Popup
      trigger={() => {
        return <button>{t('Landing.FeaturesButton')}</button>;
      }}
      modal={true}
      className="landing-features"
      closeOnDocumentClick={true}
    >
      {(close: () => void) => (
        <>
          <div className="landing-features">
            <div className="landing-feature">
              <img src={'feature3.svg'} alt="" />
              <span>{t('Landing.ArtistsCaption')}</span>
              <p>{t('Landing.ArtistsDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature2.svg'} alt="" />
              <span>{t('Landing.GenresCaption')}</span>
              <p>{t('Landing.GenresDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature1.svg'} alt="" />
              <span>{t('Landing.AlbumsCaption')}</span>
              <p>{t('Landing.AlbumsDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature4.svg'} alt="" />
              <span>{t('Landing.PlayCaption')}</span>
              <p>{t('Landing.PlayDesc')}</p>
            </div>
          </div>
          <div>
            <button
              className="landing-button landing-features-back"
              onClick={() => close()}>
              {t('Landing.Back')}
            </button>
          </div>
        </>
      )}
    </Popup>
  );
}

function _whyLoginPopup(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Popup
      trigger={() => {
        return <button>{t('Landing.WhyLoginLink')}</button>;
      }}
      modal={true}
      className="landing-features"
      closeOnDocumentClick={true}
    >
      {(close: () => void) => (
        <>
          <div className="landing-features">
            <div className="landing-feature">
              <img src={'feature3.svg'} alt="" />
              <span>{t('Landing.BridgeCaption')}</span>
              <p>{t('Landing.BridgeDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature2.svg'} alt="" />
              <span>{t('Landing.YourMixCaption')}</span>
              <p>{t('Landing.YourMixDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature1.svg'} alt="" />
              <span>{t('Landing.LibraryCaption')}</span>
              <p>{t('Landing.LibraryDesc')}</p>
            </div>
            <div className="landing-feature">
              <img src={'feature4.svg'} alt="" />
              <span>{t('Landing.NowPlayingCaption')}</span>
              <p>{t('Landing.NowPlayingDesc')}</p>
            </div>
          </div>
          <div>
            <button
              className="landing-button landing-features-back"
              onClick={() => close()}>
              {t('Landing.Back')}
            </button>
          </div>
        </>
      )}
    </Popup>
  );
}

export default function Landing(props: LandingProps): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    track(TrackingEvent.screenView, {
      app_name: 'Unchartify',
      screen_name: 'Landing Page',
    });
  }, []);

  const mobileClass = isMobile ? ' mobile' : '';

  const featuresPopup = _featuresPopup();
  const whyLoginPopup = _whyLoginPopup();

  const headline =
    i18n.language === 'en' ? (
      <>
        {t('Landing.Headline1')} <span>{t('Landing.Headline2')}</span>{' '}
        {t('Landing.Headline3')}
      </>
    ) : (
      <>
        {t('Landing.Headline1')} {t('Landing.Headline2')}
      </>
    );

  return (
    <div className={'landing' + mobileClass}>
      <div className="landing-content">
        <Logo iconSize={isMobile ? 50 : 75}/>
        <div className="landing-slogan">{headline}</div>
        <div className="landing-description">
          <p>{t('Landing.Description')}</p>
          {featuresPopup}
        </div>
        <div className="landing-login">
          <a
            href={props.authRedirectUrl}
            className="landing-button">
            <span>{t('Landing.Login')}</span>
          </a>
          <a
            href={'?guest'}
            className="landing-button">
            <span>{t('Landing.SkipLogin')}</span>
          </a>
        </div>
        <div className="landing-why-login">
          {whyLoginPopup}
        </div>
        <div className="landing-legal">
          <SocialLinks size={32} />
          {t('Footer.Copyright')}
        </div>
      </div>
      <div className="landing-captain">
        <img
          src={isMobile ? 'landing-mobile.svg' : 'landing.svg'}
          alt={t('Landing.Captain')}
        />
      </div>
    </div>
  );
}