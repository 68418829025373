import {IStore} from '../store';
import {IYourMixStore} from './yourMix.interface';
import SpotifyTrack from '../../spotify/spotifyTrack';
import {TracksSortingOption} from '../../your-mix/tracksSortingMenu';

export const yourMixSelector: (state: IStore) => IYourMixStore =
  (state: IStore) => state.yourMix;

export const collectedTracksSelector: (state: IStore) => SpotifyTrack[] =
  (state: IStore) => yourMixSelector(state).tracks;

export const yourMixTracksSortingSelector: (state: IStore) => TracksSortingOption =
  (state: IStore) => yourMixSelector(state).sorting;

export const yourMixSuffixSelector: (state: IStore) => string =
  (state: IStore) => yourMixSelector(state).mixSuffix;

export const isTrackCollected: (id: string) => (state: IStore) => boolean =
  (id: string) => (state: IStore) => collectedTracksSelector(state).filter(track => track.id === id).length !== 0;
