import {IDispatch, IStore} from '../store';
import {guiSettingsSelector} from './guiSettings.selector';
import {GUI_SETTINGS_STORAGE_KEY} from './guiSettings.constants';

export const persistGuiSettings: () => (dispatch: IDispatch, getState: () => IStore) =>
  void = () => (dispatch: IDispatch, getState: () => IStore) => {

    const state = getState();
    const guiSettings = guiSettingsSelector(state);
    localStorage.setItem(GUI_SETTINGS_STORAGE_KEY, JSON.stringify(guiSettings));
  };
