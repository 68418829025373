import {useTranslation} from 'react-i18next';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {track, TrackingEvent} from '../util/track';
import {isMobile} from 'react-device-detect';
import SpotifyArtist from '../spotify/spotifyArtist';
import {toTitleCase} from '../util/titleCase';
import {getFriendlyDuration} from '../util/getFriendlyDuration';
import SpotifyTrack from '../spotify/spotifyTrack';
import {Popularity} from '../popularity/popularity';
import ArtistMenu from '../menus/artist-menu/artistMenu';
import AlbumMenu from '../menus/album-menu/albumMenu';
import {AlbumsList} from '../albums-list/albumsList';
import {arrayShuffle} from '../util/arrayShuffle';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import {AlbumsListSortingMenu, AlbumsListSortingOption} from '../albums-list/albumsListSortingMenu';
import {Tracklist} from '../tracklist/tracklist';
import useCollectAlbumTracks, {AlbumTracksCount} from '../hooks/albums/useCollectAlbumTracks';
import AlbumMixMenuButton from '../menu-buttons/album-mix-menu-button/albumMixMenuButton';
import usePlayAlbum from '../hooks/player/usePlayAlbum';
import ServerApiContext from '../server-api/serverApiContext';

import 'react-tabs/style/react-tabs.css';
import './albumDetails.css';
import './albumDetailsMobile.css';

type AlbumDetailsProps = {
    albumId: string
}

function AlbumDetails(props: AlbumDetailsProps): JSX.Element {

  const {t} = useTranslation();
  const spotify = useContext(SpotifyApiContext);
  const serverApi = useContext(ServerApiContext);

  const detailsTabs = new Map<string, string>();
  detailsTabs.set('tracklist', t('AlbumDetails.Tracklist'));
  detailsTabs.set('similar', t('AlbumDetails.SimilarAlbums'));

  const [album, setAlbum] = useState<SpotifyAlbum>();
  const [tracks, setTracks] = useState<SpotifyTrack[]>([]);
  const [albumArtists, setAlbumArtists] = useState<SpotifyArtist[]>([]);
  const [similar, setSimilar] = useState<SpotifyAlbum[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canPlay, setCanPlay] = useState<boolean>(spotify.canPlay());
  const [showCoverImage, setShowCoverImage] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<string|undefined>();
  const [mobileSelectedTab, setMobileSelectedTab] = useState<string>('tracklist');
  const [mobileSortingOption, setMobileSortingOption] =
      useState<AlbumsListSortingOption>(AlbumsListSortingOption.relevancy);

  const collectAlbumsTracks = useCollectAlbumTracks();
  const playAlbum = usePlayAlbum();

  useEffect(() => {
    activateGenericBackground(album?.images?.[0]?.url);
    return () => deactivateGenericBackground();
  }, [album]);

  useEffect(() => {
    const setCanPlayHandler = () => setCanPlay(spotify.canPlay());
    spotify.playbackAbilityChange.addEventListener(
      'playbackAbilityChange', setCanPlayHandler);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', setCanPlayHandler);
    };
  }, [spotify]);

  useEffect(() => {
    setLoading(true);
    spotify.getAlbum(props.albumId).then(album => {
      const artistIds = album.artists.map(a => a.id);
      const trackIds = album.tracks.map(t => t.id);

      Promise.all([
        spotify.getArtists(artistIds),
        spotify.getTracks(trackIds),
        spotify.getTracksRecommendations(arrayShuffle(album.tracks).slice(0, 4).map(t => t.id))
      ]).then(results =>
      {
        setAlbum(album);
        setAlbumArtists(results[0] as SpotifyArtist[]);
        setTracks(results[1] as SpotifyTrack[]);
        setCoverImage(undefined);

        const similarAlbums = new Map<string, SpotifyAlbum>();
        (results[2] as SpotifyTrack[]).forEach(track => {
          if (track.album?.id && track.album.id !== album.id) {
            similarAlbums.set(track.album?.id, track.album);
          }
        });
        setSimilar(Array.from(similarAlbums.values()));
        setMobileSelectedTab('tracklist');
        setLoading(false);

        track(TrackingEvent.screenView, {
          'screen_name': 'Album Details',
          'item_id': album.id,
          'item_name': album.name
        });

        // todo: another stupid hack to auto-select first tab :-(
        (document.getElementsByClassName('react-tabs__tab')[0] as HTMLElement)?.click();
      });
    });
  }, [spotify, props.albumId]);

  const handlePlayAlbum = useCallback(() => album && playAlbum(album), [album, playAlbum]);

  const showCoverArt = async () => {
    if (album) {
      if (!coverImage) {
        setLoading(true);
        const amCover = await serverApi.getCoverArt(album.artists[0].name, album.name);
        setCoverImage(amCover ? amCover.url : album.images?.[0].url);
        setLoading(false);
      }
      setShowCoverImage(true);
    }
  };

  const addAlbumTracksToMix = async (count: AlbumTracksCount) => {
    setLoading(true);
    // todo: add mix suffix
    await collectAlbumsTracks((album) ? [album] : [], count, '');
    setLoading(false);
  };

  const isVA = albumArtists?.length === 1 && albumArtists[0].name.toLowerCase() === 'various artists';

  const artists = albumArtists?.map(a => {
    const artist = <div className='album-details-artist' key={a.id}>
      {!isVA && <span className='album-details-artist-image' style={{backgroundImage: 'url(' + a.images[2].url + ')'}}/>}
      <span className='album-details-artist-name'>{a.name}</span></div>;

    return !isVA ? <ArtistMenu key={'am-' + a.id} artist={a}>{artist}</ArtistMenu> : artist;
  });

  const tracklist = <Tracklist tracks={tracks} displayCoverArt={false} />;

  const spinner = (loading)
    ? <div className='spinner inline'/>
    : null;

  const albumName = (album) ? album.name : 'Loading...';

  const copyrights = <span>{album?.copyrights[0]}</span>;

  const image = (album?.images?.length)
    ? <img className='album-image'
      src={album.images[0].url}
      onClick={showCoverArt} alt=''/>
    : <div className='album-image no-cover'/>;

  const coverImageViewer = (coverImage)
    ? <Lightbox
      mainSrc={coverImage}
      onCloseRequest={() => setShowCoverImage(false)}/>
    : null;

  const albumType = (album) ? toTitleCase(t('AlbumDetails.AlbumType.' + album.type)) : '';
  const albumReleaseDate = (new Date(Date.parse(album?.releaseDate ?? ''))).toLocaleDateString();
  const albumTrackCount = t('AlbumDetails.SongsCount', {count: album?.tracks?.length ?? 0});
  const albumDuration = getFriendlyDuration(album?.tracks?.reduce((value, t2) => value + t2.duration, 0) ?? 0);

  if (!isMobile) {
    const tabs = Array.from(detailsTabs.values()).map(t => <Tab key={t}>{t}</Tab>);

    return <div className='album-details'>
      <div className='album'>
        {image}
        <div className='album-info'>
          <div className='album-info-artists'>
            {artists}
          </div>
          <div className='album-name'>
            {albumName} {album && <AlbumMenu album={album}><span className='album-menu'>⋯</span></AlbumMenu>}
          </div>
          <div className='album-info-stats'>
            <span className='album-label'>
              {album && album.label && <a href={`#labels/${album.label}`}>{album.label}</a>}
            </span>
            <span>•</span>
            <span>{albumReleaseDate}</span><span>•</span>
            <span>{albumType}</span><span>•</span>
            <span>{albumTrackCount}</span><span>•</span>
            <span>{albumDuration}</span>{!!album?.popularity && <span>•</span>}
            <span className='album-stats-popularity'>{!!album?.popularity && <Popularity value={album.popularity} />}</span>
          </div>
        </div>
        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayAlbum}>
          <img src={'play.svg'} alt={t('AlbumDetails.PlayButtonAlt')}/>
        </div>
      </div>
      <Tabs defaultIndex={0}>
        <TabList>
          {tabs}
        </TabList>
        <TabPanel>
          <AlbumMixMenuButton numberOfTracksSelected={addAlbumTracksToMix} />
          <div className='album-details-tracklist'>
            {tracklist}
          </div>
          <div className='album-details-copyrights'>
            {copyrights}
          </div>
        </TabPanel>
        <TabPanel>
          <AlbumsList albums={similar} mixSuffix={album?.name + ((album?.name ?? false) ? '+' : '') } />
        </TabPanel>
      </Tabs>
      {showCoverImage && coverImageViewer}
      {spinner}
    </div>;
  } else {
    const tabMenuItems = Array.from(detailsTabs.keys()).map(tab => {
      return <MenuItem
        key={tab}
        type='checkbox'
        checked={mobileSelectedTab === tab}
        onClick={() => setMobileSelectedTab(tab)}>
        {detailsTabs.get(tab)}
      </MenuItem>;
    });
    return <div className='album-details'>
      <div className='album-cover'>
        {image}
        <div className='album-info'>
          <div className='album-name'>
            {albumName}
          </div>
          <div className='album-info-artists'>
            {artists}
          </div>
        </div>
        {album && <div className='album-menu'><AlbumMenu album={album}>⋯</AlbumMenu></div>}
      </div>
      <div className='album-info-stats'>
        <div className='album-stats-values'>
          <span className='album-label'>
            {album && album.label && <a href={`#labels/${album.label}`}>© {album.label}</a>}
          </span>
          <span>{albumReleaseDate}</span>
          <span>{albumType}</span>
          <span>{albumTrackCount}</span>
          <span>{albumDuration}</span>
          <span className='album-stats-popularity'>{!!album?.popularity && <Popularity value={album.popularity} />}</span>
        </div>
        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayAlbum}>
          <img src={'play.svg'} alt={t('AlbumDetails.PlayButtonAlt')}/>
        </div>
      </div>
      <div className={'album-details-mobile-actions' + ((mobileSelectedTab === 'similar') ? ' similar' : '')}>
        <Menu
          menuButton={
            <MenuButton className='tab-menu-button' disabled={loading}>
              {detailsTabs.get(mobileSelectedTab)}
              <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
            </MenuButton>
          }
          className='tab-menu'
          theming='dark'>
          {tabMenuItems}
        </Menu>
        {mobileSelectedTab === 'tracklist' && <AlbumMixMenuButton
          numberOfTracksSelected={addAlbumTracksToMix}
        />}
        {mobileSelectedTab === 'similar' && <AlbumsListSortingMenu
          selectedOption={mobileSortingOption}
          optionSelectionHandler={setMobileSortingOption}
        />}
      </div>
      {mobileSelectedTab === 'tracklist' && <>
        <div className='album-details-tracklist'>
          {tracklist}
        </div>
        <div className='album-details-copyrights'>
          {copyrights}
        </div>
      </>}
      {mobileSelectedTab === 'similar' && <AlbumsList
        albums={similar}
        integratedSorting={false}
        sorting={mobileSortingOption}
      />}
      {showCoverImage && coverImageViewer}
      {spinner}
    </div>;
  }
}

export default AlbumDetails;
