import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {Menu, MenuDivider, MenuItem, SubMenu} from '@szhsin/react-menu';
import i18n from 'i18next';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import SpotifyUserProfile from '../spotify/spotifyUserProfile';
import {permissionsSelector} from '../redux/permissions/permissions.selector';
import useSubscriberFeaturesMenuItems from '../menus/subscriber-features-menu-items/useSubscriberFeaturesMenuItems';
import AuthenticatorContext from '../authenticator/authenticatorContext';

import {ReactComponent as UserPicIcon} from '../icons/userpic.svg';
import {ReactComponent as LocaleIcon} from '../icons/locale.svg';
import {ReactComponent as LogoutIcon} from '../icons/logout.svg';

import './userMenu.css';

type UserMenuProps = {
  profile: SpotifyUserProfile;
};

export default function UserMenu(props: UserMenuProps): JSX.Element {
  const { t } = useTranslation();
  const authenticator = useContext(AuthenticatorContext);
  const permissions = useSelector(permissionsSelector);
  const profile = props.profile;

  const openProfile = () => {
    window.open(profile.url, '_blank');
  };

  const switchLanguage = (lang: string) => {
    document.location.replace(document.location.protocol + '//' +
      document.location.host + '?' + lang + document.location.hash);
  };

  const logout = () => {
    authenticator.logout();
  };

  const languageLinks = ['en', 'ru'].map(lang => {
    return <MenuItem
      key={'lang-' + lang}
      type='checkbox'
      checked={i18n.language === lang}
      onClick={() => switchLanguage(lang)}>{t('lang.' + lang)}
    </MenuItem>;
  });

  const avatarImage = (profile.avatar) ? profile.avatar : 'user-avatar.png';
  const hasProfile = permissions.canViewProfile;
  const hasLogout = permissions.isSubscriber;
  const subscriberFeatures = useSubscriberFeaturesMenuItems();

  return (
    <Menu
      className='user-menu'
      portal={true}
      arrow={true}
      align='start'
      menuButton={
        <div className='user-menu-button'>
          <img
            className='icon-arrow'
            src={'user-down.svg'}
            height={12}
            alt='Arrow up icon'
          />
          {!isMobile && <span className='user-menu-name'>{profile.name}</span>}
          <img
            className='user-menu-avatar'
            src={avatarImage}
            alt='Your user avatar icon'
          />
        </div>
      }
      theming='dark'
    >
      {hasProfile && <MenuItem onClick={openProfile}>
        <UserPicIcon />
        {t('Open profile')}
      </MenuItem>}
      <SubMenu
        label={
          <>
            <LocaleIcon />
            {t('Switch language')}
          </>
        }
      >
        {languageLinks}
      </SubMenu>
      {hasLogout && <MenuItem onClick={logout}>
        <MenuDivider />
        <LogoutIcon />
        {t('Logout')}
      </MenuItem>}
      {subscriberFeatures}
    </Menu>
  );
}
