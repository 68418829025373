import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import SpotifyTrack from '../../spotify/spotifyTrack';
import SpotifyApiContext from '../../spotify/spotifyApiContext';

import useAddToPlaylist from './useAddToPlaylist';
import useAddPlaylist from './useAddPlaylist';

function useAddToNewPlaylist(): (playlistName: string, tracks: SpotifyTrack[]) => void {
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const addPlaylist = useAddPlaylist();
  const addToPlaylist = useAddToPlaylist();

  return async (name: string, tracks: SpotifyTrack[]) => {
    try {
      const description = t('PlaylistDescription');
      const playlist = await api.createUserPlaylist(name, description);
      await addToPlaylist(playlist, tracks);
      const updatedPlaylist = await api.getUserPlaylist(playlist.id);
      addPlaylist(updatedPlaylist);
    } catch (error) {
      toast(t('TracksCollector.CreatePlaylistFailed'), {
        type: 'error',
        position: 'top-center'
      });
      console.log(error);
    }
  };
}

export default useAddToNewPlaylist;
