import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {yourMixStoreInitialState} from './yourMix.constants';
import SpotifyTrack from '../../spotify/spotifyTrack';
import {IYourMixStore} from './yourMix.interface';
import {TracksSortingOption} from '../../your-mix/tracksSortingMenu';

export const yourMixStoreSlice = createSlice({
  name: 'tracksCollector',
  initialState: yourMixStoreInitialState,
  reducers: {
    setYourMixState(state, action: PayloadAction<IYourMixStore>) {
      state.tracks = action.payload.tracks;
      state.sorting = action.payload.sorting;
    },

    collectTrack(store, action: PayloadAction<SpotifyTrack>) {
      if (!store.tracks.some(t => t.id === action.payload.id)) {
        store.tracks = [...store.tracks, action.payload];
      }
    },

    collectTracks(store, action: PayloadAction<ReadonlyArray<SpotifyTrack>>) {
      for (const track of action.payload) {
        if (!store.tracks.some(t => t.id === track.id)) {
          store.tracks.push(track);
        }
      }
    },

    removeTrack(store, action: PayloadAction<SpotifyTrack>) {
      store.tracks = store.tracks.filter(track => track.id !== action.payload.id);
    },

    emptyCollector(state) {
      state.tracks.splice(0, state.tracks.length);
      state.sorting = TracksSortingOption.added;
    },

    setSorting(store, action: PayloadAction<TracksSortingOption>) {
      store.sorting = action.payload;
    },

    setMixSuffix(store, action: PayloadAction<string>) {
      store.mixSuffix = action.payload;
    },

    resetMixSuffix(store) {
      store.mixSuffix = '';
    }
  }
});
