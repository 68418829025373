import React from 'react';
import {Menu} from '@szhsin/react-menu';
import {useTranslation} from 'react-i18next';

import {AlbumTracksCount} from '../../hooks/albums/useCollectAlbumTracks';
import useAlbumMixMenuItems from '../../menus/album-mix-menu-items/useAlbumMixMenuItems';

import './albumMixMenuButton.css';

type AlbumMixMenuButtonProps = {
    numberOfTracksSelected(count: AlbumTracksCount): void,
    disabled?: boolean
}

export default function AlbumMixMenuButton(props: AlbumMixMenuButtonProps): JSX.Element {
  const {t} = useTranslation();
  return <Menu
    className="album-mix-menu"
    portal={true}
    arrow={true}
    align="start"
    menuButton={<div className="album-mix-menu-button">
      <img src={'playlist.svg'} alt={t('AlbumMenu.CollectTracks')}/>
      <span>{t('AlbumMenu.CollectTracks')}</span>
    </div>}
    theming="dark">
    {useAlbumMixMenuItems(props.numberOfTracksSelected)}
  </Menu>;
}
