import React from 'react';
import {useTranslation} from 'react-i18next';
import {Menu, MenuButton} from '@szhsin/react-menu';
import useAlbumMixMenuItems from '../../menus/album-mix-menu-items/useAlbumMixMenuItems';
import {AlbumTracksCount} from '../../hooks/albums/useCollectAlbumTracks';

import '../../collect-button/collectButton.css';

export type CollectButtonProps = {
    onClick(count: AlbumTracksCount) : void
}

const CollectButton: (props: CollectButtonProps) => JSX.Element = (props: CollectButtonProps) => {
  const {t} = useTranslation();

  return <div className="collect-button">
    <Menu
      className="album-mix-menu"
      portal={true}
      arrow={true}
      align="start"
      menuButton={<MenuButton><img src={'playlist.svg'} alt={t('TracksCollector.CollectButtonAlt')}/></MenuButton>}
      theming="dark">
      {useAlbumMixMenuItems(props.onClick)}
    </Menu>
  </div>;
};

export default CollectButton;
