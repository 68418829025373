import React from 'react';
import SpotifyArtist from '../spotify/spotifyArtist';
import ArtistMenu from '../menus/artist-menu/artistMenu';

import './artistTile.css';

type ArtistTileProps = {
    artist: SpotifyArtist
}

function WrappedArtistTile(props: ArtistTileProps): JSX.Element {
  const artist = props.artist;
  const artistImage = (artist.images.length)
    ? <div className="artist-image" style={{backgroundImage: 'url(' + props.artist.images[0].url + ')'}} />
    : <div className="artist-image no-cover" />;

  return <div className="artist-tile">
    <ArtistMenu artist={artist}>
      <div>
        {artistImage}
        <a className="artist-name">{artist.name}</a>
      </div>
    </ArtistMenu>
  </div>;
}

export const ArtistTile = React.memo(WrappedArtistTile);
