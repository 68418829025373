import {useAppDispatch} from '../../redux/store';
import {useContext, useState} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {playlistsStoreSlice} from '../../redux/playlists/playlists.slice';

function useInitPlaylists(): [() => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const api = useContext(SpotifyApiContext);

  const initPlaylists = () => {
    setIsLoading(true);

    return api
      .getUserPlaylists()
      .then(playlists => {
        const ownedPlaylists = playlists.filter(p => p.owner === api.getUserProfile().id);
        ownedPlaylists.sort((p1, p2) => p1.name.localeCompare(p2.name));
        dispatch(playlistsStoreSlice.actions.addPlaylists(ownedPlaylists));
      })
      .catch((error) => {
        console.error('useInitPlaylists error', error);

        toast(t('Playlists.InitErr'), {
          type: 'error',
          position: 'top-center',
          autoClose: 6000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return [initPlaylists, isLoading];
}

export default useInitPlaylists;
