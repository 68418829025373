import React, {useContext, useEffect, useState} from 'react';
import ReactFlow, {FlowElement} from 'react-flow-renderer';
import {isMobile} from 'react-device-detect';
import dagre from 'dagre';
import ArtistNode from './artistNode';
import FlowchartNode from './artistFlowchartNode';
import SpotifyArtist from '../spotify/spotifyArtist';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {getLayoutElements} from '../util/getLayoutElements';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import useCollectArtistTracks from '../hooks/artists/useCollectArtistTracks';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../hooks/artists/artistTracksCollectionEnums';
import CollectButton from './collect-button/collectButton';

import './artistExplorer.css';

type ArtistExplorerProps = {
    rootArtistId: string
}

const getNodeFromArtist = (artist: SpotifyArtist, relatedArtists: SpotifyArtist[] = [], expanded = false) : ArtistNode => {
  return {
    id: artist.id,
    artist: artist,
    relatedArtists: relatedArtists.map((relatedArtist: SpotifyArtist) => relatedArtist.id),
    expanded: expanded
  };
};

const ArtistExplorer: (props: ArtistExplorerProps) => JSX.Element = (props: ArtistExplorerProps) => {
  const spotifyApi = useContext(SpotifyApiContext);
  const [nodesCache, setNodesCache] = useState<Map<string, ArtistNode>>(new Map<string, ArtistNode>());
  const [rootNode, setRootNode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [dagreGraph, setDagreGraph] = useState<dagre.graphlib.Graph>(new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({})));
  const collectArtistTracks = useCollectArtistTracks();

  const backgroundUrl = nodesCache.get(rootNode)?.artist?.images?.[0]?.url;

  const nodesToShow: string[] = [];

  const addNode = (artist: SpotifyArtist, relatedArtists: SpotifyArtist[],
    expanded = false, root = false) : void => {

    const cache = new Map<string, ArtistNode>((root) ? [] : nodesCache);
    cache.set(artist.id, getNodeFromArtist(artist, relatedArtists, expanded));
    relatedArtists.forEach((artist: SpotifyArtist) =>
      cache.set(artist.id, getNodeFromArtist(artist, [], false)));

    setNodesCache(cache);
    if (root) {
      setRootNode(artist.id);
    }
  };

  const getRelatedArtists = async (artist: SpotifyArtist): Promise<SpotifyArtist[]> =>
    await spotifyApi.getSimilarArtists(artist.id);

  useEffect(() => {
    activateGenericBackground(nodesCache.get(rootNode)?.artist?.images?.[0]?.url);
    return () => deactivateGenericBackground();
  }, [backgroundUrl]);

  useEffect(() => {
    setLoading(true);
    spotifyApi.getArtist(props.rootArtistId).then(artist => {
      getRelatedArtists(artist).then(related => {
        addNode(artist, related, true, true);
        setDagreGraph(new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({})));
        setLoading(false);
      });
    });
  }, [props.rootArtistId, spotifyApi]);

  const addRelated = (id: string, relatedArtists: SpotifyArtist[]) : void => {
    const node = nodesCache.get(id);
    if (node && !node.relatedArtists.length) {
      const cache = new Map<string, ArtistNode>(nodesCache);
      const artistNode = cache.get(id);
      if (!artistNode) {
        return;
      }
      relatedArtists.forEach((artist: SpotifyArtist): void => {
        if (!cache.has(artist.id)) {
          artistNode.relatedArtists.push(artist.id);
          cache.set(artist.id, getNodeFromArtist(artist));
        }
      });
      artistNode.expanded = true;
      setNodesCache(cache);
    }
  };

  const toggleNode = (id: string) : void => {
    const cache = new Map<string, ArtistNode>(nodesCache);
    const node = cache.get(id);
    if (node) {
      node.expanded = !node.expanded;
      if (!node.expanded) {
        const collapseNodes = function(node: ArtistNode) {
          node.expanded = false;
          node.relatedArtists.forEach(function(id: string) {
            const node = cache.get(id);
            if (node) {
              collapseNodes(node);
            }
          });
        };
        collapseNodes(node);
      }
      setNodesCache(cache);
    }
  };

  const isExpanded = (id: string) : boolean =>
    nodesCache.get(id)?.expanded ?? false;

  const toggleArtist = async (id: string): Promise<void> => {
    if (!isExpanded(id)) {
      const node = nodesCache.get(id);
      if (!node) {
        return;
      }
      if (!node.relatedArtists.length) {
        setLoading(true);
        addRelated(id, await spotifyApi.getSimilarArtists(id));
        setLoading(false);
      } else {
        toggleNode(id);
      }
    } else {
      toggleNode(id);
    }
  };

  const collectArtistsTracks = async (count: ArtistTracksCount, mode: ArtistTracksCollectionMode) => {
    setLoading(true);
    const artists : SpotifyArtist[] = [];
    for (const node of nodesToShow) {
      const n = nodesCache.get(node);
      if (n) {
        artists.push(n.artist);
      }
    }
    const rNode = nodesCache.get(rootNode);
    const mixSuffix = (rNode) ? rNode.artist.name + '+' : '';
    await collectArtistTracks(artists, count, mode, mixSuffix);
    setLoading(false);
  };

  const elements: FlowElement[] = [];
  nodesCache.forEach((node: ArtistNode) => {
    if (node.expanded || node.id === rootNode) {
      nodesToShow.push(node.id);
    }
    if (node.expanded) {
      nodesToShow.push(...node.relatedArtists);
    }
  });

  nodesCache.forEach((node: ArtistNode, id: string) => {
    if (nodesToShow.indexOf(id) === -1) {
      return;
    }
    const nodeComponent =
      <FlowchartNode
        artist={node.artist}
        expanded={node.expanded}
        toggleHandler={() => toggleArtist(id)}
      />;

    elements.push({
      id: node.id,
      data: {label: nodeComponent},
      position: {x: 0, y: 0},
      className: 'flowchart-node'
    });
    if (node.expanded) {
      node.relatedArtists.forEach(artist => {
        elements.push({
          id: node.id + '-' + artist,
          source: node.id,
          target: artist
        });
      });
    }
  });

  const spinner = (loading)
    ? <div className="spinner"/>
    : null;

  const flowchartElements = getLayoutElements(dagreGraph, elements, 'LR',
    undefined, (isMobile) ? 60 : undefined, undefined, 80);
  const flow = (flowchartElements.length)
    ? <ReactFlow
      elements={flowchartElements}
      nodesConnectable={false}
      elementsSelectable={false}
      nodesDraggable={false}
    />
    : null;

  return (
    <div className="spotify-explorer">
      <CollectButton onClick={collectArtistsTracks} />
      {flow}
      {spinner}
    </div>
  );
};

export default ArtistExplorer;
