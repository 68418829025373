import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {playlistsStoreInitialState} from './playlists.constants';
import {SpotifyPlaylist} from '../../spotify/spotifyPlaylist';

export const playlistsStoreSlice = createSlice({
  name: 'playlists',
  initialState: playlistsStoreInitialState,
  reducers: {
    addPlaylists(store, action: PayloadAction<SpotifyPlaylist[]>) {
      store.playlists = [...store.playlists, ...action.payload];
    },
    addPlaylist(store, action: PayloadAction<SpotifyPlaylist>) {
      store.playlists = [...store.playlists, action.payload]
        .sort((p1, p2) => p1.name.localeCompare(p2.name));
    }
  }
});
