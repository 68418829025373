import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import SpotifyArtist from '../spotify/spotifyArtist';
import useCollectArtistTracks from '../hooks/artists/useCollectArtistTracks';
import {ArtistMixCustomMenuButton} from '../menu-buttons/artist-mix-custom-menu-button/artistMixCustomMenuButton';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../hooks/artists/artistTracksCollectionEnums';

import './artistMixTile.css';

type ArtistMixTileProps = {
    artists: SpotifyArtist[],
    caption?: string,
    mixSuffix?: string
}

export default function ArtistMixTile(props: ArtistMixTileProps): JSX.Element {
  const {t} = useTranslation();
  const [busy, setBusy] = useState<boolean>(false);
  const collectArtistTracks = useCollectArtistTracks();
  const caption = props.caption ?? t('ArtistMenu.CollectTracks');
  const mixSuffix = props.mixSuffix ?? '';

  const addToMix = async (count: ArtistTracksCount, mode: ArtistTracksCollectionMode) => {
    setBusy(true);
    await collectArtistTracks(props.artists, count, mode, mixSuffix);
    setBusy(false);
  };
  return <div className="artist-mix-tile">
    <ArtistMixCustomMenuButton numberOfTracksSelected={addToMix}>
      <div>
        <div className="artist-mix-tile-image">
          <img src={'playlist.svg'} alt={caption}/>
        </div>
        <span className="artist-mix-caption">{caption}</span>
      </div>
    </ArtistMixCustomMenuButton>
    {busy && <div className="spinner inline"/>}
  </div>;
}
