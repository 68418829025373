import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {permissionsSelector} from '../../redux/permissions/permissions.selector';
import {MenuDivider, MenuHeader, MenuItem} from '@szhsin/react-menu';
import AuthenticatorContext from '../../authenticator/authenticatorContext';

export default function useSubscriberFeaturesMenuItems(separated = true) : JSX.Element[] {
  const {t} = useTranslation();
  const authenticator = useContext(AuthenticatorContext);

  const permissions = useSelector(permissionsSelector);
  if (permissions.isSubscriber) {
    return [];
  }

  const loginToSpotify = () => {
    window.location.replace(authenticator.getAuthRedirectUrl());
  };

  return [
    ...separated ? [<MenuDivider key={'sbfs-divider'} />] : [],
    <MenuHeader key={'sbfs-header'}>
      {t('SubscriberMenu.MoreFeatures')}
    </MenuHeader>,
    <MenuItem key={'amctt-subscriber-login'} onClick={loginToSpotify}>
      {t('SubscriberMenu.MoreFeaturesDesc')}
    </MenuItem>
  ];
}
