import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import SuggestInput from '../suggest-input/suggestInput';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {track, TrackingEvent} from '../util/track';
import Switch from 'react-switch';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {AlbumTile} from '../album-tile/albumTile';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import {savedAlbumIdsSelector} from '../redux/albums/albums.selector';
import {AlbumsSuggestionsLoader} from './albumSuggestionsLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import {permissionsSelector} from '../redux/permissions/permissions.selector';

import './albumBrowser.css';

enum AlbumSelectionMode {
    Saved = 'saved',
    Any = 'any'
}

const searchSpotifyAlbums = AwesomeDebouncePromise((loader: AlbumsSuggestionsLoader, query: string) => {
  loader.init(query);
  return loader.nextSuggestions();
}, 500);

const filterOutCompilations = (albums: SpotifyAlbum[]) => albums.filter(a => a.type !== 'compilation');

const AlbumBrowser: () => JSX.Element = () => {

  const { t } = useTranslation();
  const spotifyApi = useContext(SpotifyApiContext);
  const suggestionsLoader: AlbumsSuggestionsLoader = useMemo(() => {
    return new AlbumsSuggestionsLoader(spotifyApi);
  }, [spotifyApi]);

  const permissions = useSelector(permissionsSelector);

  const [filter, setFilter] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SpotifyAlbum[]>([]);
  const [selectionMode, setSelectionMode] = useState<AlbumSelectionMode>(
    permissions.hasLibrary ? AlbumSelectionMode.Saved : AlbumSelectionMode.Any);
  const [savedAlbums, setSavedAlbums] = useState<SpotifyAlbum[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const savedAlbumIds = useSelector(savedAlbumIdsSelector);

  const isFiltered = filter !== '';
  const isOnlySavedMode = selectionMode === AlbumSelectionMode.Saved;

  const suggestSavedAlbums = (value: string) : SpotifyAlbum[] => {
    return savedAlbums.slice().filter(album => {
      const artists = (album.artists)
        ? album.artists.map(a => a.name).join(' ')
        : '';
      return (artists.toLowerCase() + ' ' + album.name.toLocaleLowerCase()).match(value.toLocaleLowerCase());
    });
  };

  const suggestAnyAlbums = async (query: string): Promise<SpotifyAlbum[]> => {
    if (query.trim().length < 2) {
      return [];
    }
    return filterOutCompilations(await searchSpotifyAlbums(suggestionsLoader, query));
  };

  useEffect(() => {
    if (!isOnlySavedMode) {
      return;
    }
    setLoading(true);
    spotifyApi
      .getUserSavedAlbums()
      .then((albums: Array<SpotifyAlbum>) => {
        const savedAlbums = filterOutCompilations(albums);
        setSavedAlbums(savedAlbums);
        if (savedAlbums.length) {
          setSuggestions(albums);
        } else {
          setSelectionMode(AlbumSelectionMode.Any);
        }
      })
      .finally(() => setLoading(false));
  }, [isOnlySavedMode, selectionMode, spotifyApi]);

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    setLoading(true);
    track(TrackingEvent.search, {
      'search_term': filter
    });
    if (selectionMode === AlbumSelectionMode.Saved) {
      setSuggestions(suggestSavedAlbums(filter));
      setLoading(false);
    } else {
      suggestAnyAlbums(filter).then((albums: SpotifyAlbum[]) => {
        setSuggestions(albums);
        setLoading(false);
      });
    }
  }, [savedAlbums, filter, isFiltered, selectionMode]);

  const changeSelectionMode = (checked: boolean) => {
    setSelectionMode(checked ? AlbumSelectionMode.Saved : AlbumSelectionMode.Any);
  };

  const filterAlbums = (filter: string) => {
    setFilter((filter.length) > 1 ? filter : '');
  };

  const loadSuggestions = async () => {
    if (isOnlySavedMode || suggestionsLoader.isLoading()) {
      return;
    }
    setLoading(true);
    if (suggestionsLoader.hasMoreSuggestions()) {
      const newSuggestions = await suggestionsLoader.nextSuggestions();
      setSuggestions([...suggestions, ...newSuggestions]);
    }
    setLoading(false);
  };

  const suggestedAlbums = suggestions
    .filter(album => !isOnlySavedMode || savedAlbumIds.includes(album.id))
    .map(album => <AlbumTile key={album.id} album={album} displayArtist={true} displayYear={true} />);

  const loader = loading ? <div className="spinner"/> : null;

  return <div className="album-browser">
    <div className="selection-options">
      <SuggestInput placeholder={t('Type artist or album name')} value={filter} valueChangeHandler={filterAlbums}/>
      {permissions.hasLibrary && <div className="saved-only">
        <Switch
          disabled={savedAlbumIds.length === 0}
          onChange={changeSelectionMode}
          checked={selectionMode === AlbumSelectionMode.Saved}
          className="selection-mode"
          onColor="#036551"
          offColor="#036551"
          onHandleColor="#ffffff"
          offHandleColor="#036551"
          handleDiameter={isMobile ? 26 : 36}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={isMobile ? 20 : 30}
          width={isMobile ? 48 : 58}
        />
        <div className="selection-label">
          {t('Saved only')}
        </div>
      </div>}
    </div>
    <div className="album-suggestions">
      <InfiniteScroll
        dataLength={suggestedAlbums.length}
        next={loadSuggestions}
        hasMore={true}
        loader={null}
        className="scroll-container">

        {suggestedAlbums}
      </InfiniteScroll>
    </div>
    {loader}
  </div>;
};

export default AlbumBrowser;
