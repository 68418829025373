import {IGuiSettingsStore} from './guiSettings.interface';
import {DataMode} from '../../bridge/bridgeDataLoader';
import {ApiTimeRange} from '../../spotify/api';

export const GUI_SETTINGS_STORAGE_KEY = 'guiSettingsState';

export const guiSettingsStoreInitialState: IGuiSettingsStore = {
  bridge: {
    artistsMode: DataMode.recent,
    artistsRange: ApiTimeRange.mediumTerm,
    albumsMode: DataMode.recent,
    albumsRange: ApiTimeRange.mediumTerm,
    genresMode: DataMode.recent,
    genresRange: ApiTimeRange.mediumTerm,
    labelsMode: DataMode.recent,
    labelsRange: ApiTimeRange.mediumTerm
  },
  genreExplorer: {
    options: {
      includeAlbums: true,
      includeSingles: true,
      includeVA: true,
      yearsEnabled: false,
      years: {
        from: 0,
        to: 0
      },
      exactMatch: false,
      rememberPreferences: false
    }
  },
  labelExplorer: {
    options: {
      includeAlbums: true,
      includeSingles: true,
      includeVA: true,
      yearsEnabled: false,
      years: {
        from: 0,
        to: 0
      },
      exactMatch: false,
      rememberPreferences: false
    }
  }
};
