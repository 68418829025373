import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {guiSettingsStoreInitialState} from './guiSettings.constants';
import {IBridgeGuiSettings, IAlbumSelectionSettings, IGuiSettingsStore} from './guiSettings.interface';

export const guiSettingsStoreSlice = createSlice({
  name: 'guiSettings',
  initialState: guiSettingsStoreInitialState,
  reducers: {
    setGuiSettingsState(state, action: PayloadAction<IGuiSettingsStore>) {
      state.bridge = action.payload.bridge;
      state.genreExplorer = action.payload.genreExplorer;
      state.labelExplorer = action.payload.labelExplorer;
    },
    setBridgeState(state, action: PayloadAction<Partial<IBridgeGuiSettings>>) {
      state.bridge = {
        ...state.bridge,
        ...action.payload,
      };
    },
    setGenreExplorerState(state, action: PayloadAction<Partial<IAlbumSelectionSettings>>) {
      const newState = action.payload.options?.rememberPreferences
        ? action.payload
        : guiSettingsStoreInitialState.genreExplorer;

      state.genreExplorer = {
        ...state.genreExplorer,
        ...newState
      };
    },
    setLabelExplorerState(state, action: PayloadAction<Partial<IAlbumSelectionSettings>>) {
      const newState = action.payload.options?.rememberPreferences
        ? action.payload
        : guiSettingsStoreInitialState.labelExplorer;

      state.labelExplorer = {
        ...state.labelExplorer,
        ...newState
      };
    }
  },
});