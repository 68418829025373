import React from 'react';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import AlbumMenu from '../menus/album-menu/albumMenu';
import {getYYFromReleaseDate, getYYYYFromReleaseDate} from '../util/getYearFromReleaseDate';
import {toTitleCase} from '../util/titleCase';

import './albumTile.css';

type AlbumTileProps = {
  album: SpotifyAlbum,
  displayArtist?: boolean,
  displayYear?: boolean,
  displayType?: boolean,
  displayLabel?: boolean
}

function WrappedAlbumTile(props: AlbumTileProps): JSX.Element {
  const album = props.album;
  const artists = album.artists.map(a => (a.name.toLowerCase() === 'various artists') ? 'VA' : a.name);
  const displayArtistSmart = props.displayArtist === undefined || props.displayArtist === null;
  const displayArtist = !displayArtistSmart && (props.displayArtist ?? false);
  const displayYear = props.displayYear ?? false;
  const displayLabel = props.displayLabel ?? false;
  const displayType = props.displayType ?? true;

  const label = (album.label) ? `© ${getYYYYFromReleaseDate(album.releaseDate)} ${album.label}` : album.copyrights[0];
  const type = album.type.substring(0, 1).toLowerCase();

  const typeMarker = displayType ? <span className="album-type" title={toTitleCase(album.type)}><span>{type}</span></span> : null;

  // todo: return image
  const albumImage = (album.images?.length ?? false)
    ? <div className="album-image" style={{backgroundImage: 'url(' + (album.images?.[0].url ?? '') + ')'}}>{typeMarker}</div>
    : <div className="album-image no-cover">{typeMarker}</div>;

  return <div className="album-tile">
    <AlbumMenu album={album}>
      <div>
        {albumImage}
        <span className="album-name">
          {album.name}{displayYear ? ' \'' + getYYFromReleaseDate(album.releaseDate) : ''}
        </span>
        {displayArtistSmart && artists.length > 1 && <span className="album-artist">{artists.join(', ')}</span>}
        {displayArtist && <span className="album-artist">{artists.join(', ')}</span>}
        {displayLabel && label && <span className="album-label">{label}</span>}
      </div>
    </AlbumMenu>
  </div>;
}

export const AlbumTile = React.memo(WrappedAlbumTile);
