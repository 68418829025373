import React, {FunctionComponent} from 'react';
import {Menu, MenuButton} from '@szhsin/react-menu';
import useArtistMixMenuItems from '../../menus/artist-mix-menu-items/useArtistMixMenuItems';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../../hooks/artists/artistTracksCollectionEnums';

type ArtistMixCustomMenuButtonProps = {
    numberOfTracksSelected(count: ArtistTracksCount, mode: ArtistTracksCollectionMode): void
}

export const ArtistMixCustomMenuButton: FunctionComponent<ArtistMixCustomMenuButtonProps> = ({numberOfTracksSelected, children}
    : React.PropsWithChildren<ArtistMixCustomMenuButtonProps>) => {

  return <Menu
    className="artist-mix-menu"
    portal={true}
    arrow={true}
    align="start"
    menuButton={<MenuButton className={'artist-menu-button'}>{children}</MenuButton>}
    theming="dark">
    {useArtistMixMenuItems(numberOfTracksSelected, false, false)}
  </Menu>;
};
