import React, {FunctionComponent, PropsWithChildren} from 'react';
import {Menu, MenuButton} from '@szhsin/react-menu';

import SpotifyArtist from '../../spotify/spotifyArtist';
import useArtistMenuItems from '../artist-menu-items/useArtistMenuItems';
import useSubscriberFeaturesMenuItems from '../subscriber-features-menu-items/useSubscriberFeaturesMenuItems';

import './artistMenu.css';

type ArtistMenuProps = {
    artist: SpotifyArtist
}

export const ArtistMenu: FunctionComponent<ArtistMenuProps> = ({
  artist,
  children,
}: PropsWithChildren<ArtistMenuProps>) => {

  const artistMenuItems = useArtistMenuItems([artist]);
  const subscriberFeatures = useSubscriberFeaturesMenuItems();

  return <Menu
    className={'artist-menu'}
    portal={true}
    arrow={true}
    align={'center'}
    menuButton={<MenuButton>{children}</MenuButton>}
    theming={'dark'}>

    {artistMenuItems}
    {subscriberFeatures}
  </Menu>;
};

export default ArtistMenu;
