import {useAppDispatch} from '../../redux/store';
import {persistYourMix} from '../../redux/your-mix/yourMix.actions';

function usePersistYourMix(): [() => void] {
  const dispatch = useAppDispatch();
  const persist = () => {
    dispatch(persistYourMix());
  };
  return [persist];
}

export default usePersistYourMix;
