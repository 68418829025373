import {useContext} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import useUpdateNowPlaying from './useUpdateNowPlaying';
import SpotifyArtist from '../../spotify/spotifyArtist';

function usePlayArtist(): (artist: SpotifyArtist) => void {
  const api = useContext(SpotifyApiContext);
  const updateNowPlaying = useUpdateNowPlaying();

  return async (artist: SpotifyArtist) => {
    if (artist && api.canPlay()) {
      api.playArtist(artist).then(() => setTimeout(updateNowPlaying, 500));
    }
  };
}

export default usePlayArtist;
