import React, {useContext, useEffect} from 'react';
import {toast} from 'react-toastify';
import {Trans} from 'react-i18next';
import {useAppDispatch} from '../../redux/store';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {playerStoreSlice} from '../../redux/player/player.slice';
import AuthenticatorContext from '../authenticatorContext';
import SpotifyNowPlaying from '../../spotify/spotifyNowPlaying';

import './tokenChecker.css';

export const TokenChecker: () => JSX.Element = () => {
  const api = useContext(SpotifyApiContext);
  const authenticator = useContext(AuthenticatorContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const nowPlayingCall = () => {
      api.getNowPlaying()
        .then((nowPlaying: SpotifyNowPlaying | null) => {
          dispatch(playerStoreSlice.actions.setNowPlaying(nowPlaying));
        })
        .catch((request: XMLHttpRequest) => {
          if (request.status === 401) {
            toast(
              <Trans i18nKey="Reauthorize">
                Session expired. Please, <a className="reauthorizeButton" href={authenticator.getAuthRedirectUrl()}>reauthorise</a>
              </Trans>,
              {
                toastId: 'toast-reauthorize',
                type: 'error',
                autoClose: false,
              },
            );
          }
        });
    };
    nowPlayingCall();
    window.setInterval(nowPlayingCall, 10 * 1000);
  }, [api, dispatch]);

  return (<></>);
};

