import {useAppDispatch} from '../../redux/store';
import {SpotifyPlaylist} from '../../spotify/spotifyPlaylist';
import {playlistsStoreSlice} from '../../redux/playlists/playlists.slice';

function useAddPlaylist(): (playlist: SpotifyPlaylist) => void {
  const dispatch = useAppDispatch();
  return (playlist: SpotifyPlaylist) => {
    dispatch(playlistsStoreSlice.actions.addPlaylist(playlist));
  };
}

export default useAddPlaylist;
