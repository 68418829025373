import {useAppDispatch} from '../../redux/store';
import SpotifyTrack from '../../spotify/spotifyTrack';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';

function useRemoveTrack(): [(track: SpotifyTrack) => void] {
  const dispatch = useAppDispatch();
  const dropTrack = (track: SpotifyTrack) => {
    dispatch(yourMixStoreSlice.actions.removeTrack(track));
  };
  return [dropTrack];
}

export default useRemoveTrack;
