import React from 'react';
import { ReactComponent as TwitterIcon } from '../icons/twitter.svg';
import { ReactComponent as VkIcon } from '../icons/vk.svg';
import { ReactComponent as TelegramIcon } from '../icons/telegram.svg';
import { getTwitterUrl } from '../util/getTwitterUrl';

import './socialLinks.css';

type SocialLinksProps = {
  size: number;
};

export default function SocialLinks(props: SocialLinksProps): JSX.Element {
  return (
    <div className="social-links">
      <a
        href={getTwitterUrl()}
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: `${props.size}px`, height: `${props.size}px` }}
      >
        <TwitterIcon />
      </a>
      <a
        href="https://vk.com/spotifyuncharted"
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: `${props.size}px`, height: `${props.size}px` }}
      >
        <VkIcon />
      </a>
      <a
        href="https://t.me/spotifyuncharted"
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: `${props.size}px`, height: `${props.size}px` }}
      >
        <TelegramIcon />
      </a>
    </div>
  );
}
