import { GenresGroup } from './genresManager';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import './genreGroup.css';

type GenreGroupProps = {
  group: GenresGroup;
  expanded: boolean;
  last: boolean;
};

const expandedGenresCount = isMobile ? 5 : 19;

const GenreGroup: (props: GenreGroupProps) => JSX.Element = (props: GenreGroupProps) => {
  const { t } = useTranslation();

  const [expandAll, setExpandAll] = useState<boolean>(props.expanded);

  const genres = props.group.genres.map((g) => (
    <div key={`ggc-${g}`}>
      <a key={`gg-${g}`} href={'#/genres/' + g}>
        {g}
      </a>
    </div>
  ));

  const expandedGenres = !expandAll
    ? genres.slice(0, expandedGenresCount)
    : genres;

  const collapsedGenres =
    !expandAll && genres.length > expandedGenresCount ? (
      <span className="group-collapsed-genres">
        {genres.slice(expandedGenresCount)}
      </span>
    ) : (
      []
    );

  const expandAllLink =
    !expandAll && genres.length > expandedGenresCount ? (
      <button onClick={() => setExpandAll(true)} className="expand-all">
        <span className="expand-all">
          {t('Show all')} ({genres.length})
        </span>
      </button>
    ) : null;

  const showGroupCaption = !isMobile || props.group.name !== '';
  return (
    <div
      id={'group-anchor-' + props.group.name}
      className={'genre-group' + (props.last ? ' last' : '')}
    >
      <div
        className={
          'genre-group-container' + (!showGroupCaption ? ' no-caption' : '')
        }
      >
        {showGroupCaption && (
          <div className="genre-group-caption">
            <h4>{props.group.name}</h4>
          </div>
        )}
        <div className="group-genres">
          {expandedGenres}
          {collapsedGenres}
          {expandAllLink}
        </div>
      </div>
    </div>
  );
};

export default GenreGroup;
