import {useAppDispatch} from '../../redux/store';
import {useContext, useState} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {artistsStoreSlice} from '../../redux/artists/artists.slice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

function useInitFollowedArtists(): [() => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const api = useContext(SpotifyApiContext);

  const initFollowedArtists = () => {
    setIsLoading(true);

    return api
      .getUserFollowedArtists()
      .then(artists => {
        const artistIds = artists.map(({id}) => id);

        dispatch(artistsStoreSlice.actions.addFollowedArtistIds(artistIds));
      })
      .catch((error) => {
        console.error('useInitFollowedArtists error', error);

        toast(t('Artists.InitErr'), {
          type: 'error',
          position: 'top-center',
          autoClose: 6000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [initFollowedArtists, isLoading];
}

export default useInitFollowedArtists;
